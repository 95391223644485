import React, { useEffect, useState } from 'react';
import useAuth from 'hooks/useAuth';
import ApiService from 'services/ApiService';

const Pairing = ({ setTitle, setSubTitle }) => {
    const [processing, setProcessing] = useState(null);
    const [codeGenerated, setCodeGenerated] = useState(false);
    const [code, setCode] = useState('');
    const [error, setError] = useState('');
    const { user } = useAuth();

    const onClickButtonHandler = e => {
        setProcessing(true);
        const data = new URLSearchParams();
        data.append('NoCli', user.NoCli);
        data.append('email', user.email);

        ApiService.post('user/createAuthentCode.php', data)
            .then(res => {
                if (!res.data.ret) throw new Error();

                const time = new Date(res.data.data.expires_at * 1000);

                setCodeGenerated(true);
                setTitle("Votre code d'authentification");
                setSubTitle(
                    `Utiliser le code suivant d'ans l'application Cheval TV sur Amazon Fire TV. Le code est valide jusqu'à ${
                        time.getHours() > 9 ? time.getHours() : '0' + time.getHours()
                    }h${time.getMinutes() > 9 ? time.getMinutes() : '0' + time.getMinutes()}`
                );
                setCode(res.data.data.code);
                // setSuccess('Le code vous a été envoyé par mail');
            })
            .catch(err =>
                setError(
                    'Impossible de générer votre code pour le moment. Veuillez réessayer plus tard.'
                )
            )
            .finally(() => setProcessing(false));
    };

    useEffect(() => {
        setTitle("Générer un code d'authentification unique");
        setSubTitle(
            'Cliquer sur le bouton ci-dessous pour générer votre code. Chaque code est à usage unique et reste valide pendant 10 minutes.'
        );
    }, [setTitle, setSubTitle]);

    return (
        <>
            <p className="main__text"></p>
            {!codeGenerated ? (
                <button className="button" onClick={onClickButtonHandler} disabled={processing}>
                    Générer un code
                </button>
            ) : (
                <p style={{ fontSize: '2rem', fontWeight: 'bold' }} className="main__text">
                    {`${code.substring(0, 4)}-${code.substring(4, 8)}`}
                </p>
            )}
            {error.length > 0 && (
                <p className="main__text main__text--center main__text--error">{error}</p>
            )}
            {/* {success.length > 0 && (
                <p className="main__text main__text--center main__text--success">{success}</p>
            )} */}
        </>
    );
};

export default Pairing;
