import React, { useEffect, useState, useMemo } from 'react';
import useAuth from 'hooks/useAuth';
import Input from 'components/Input';

const Registration = ({ setTitle, setSubTitle, emailValue, emailError }) => {
    const [passwordValue, setPasswordValue] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [countryValue, setCountryValue] = useState('');
    const [addressValue, setAddressValue] = useState('');
    const [postalCodeValue, setPostalCodeValue] = useState('');
    const [cityValue, setCityValue] = useState('');

    const { user, finalizeAccount } = useAuth();

    const buttonDisabled = useMemo(() => {
        return passwordError.length > 0;
    }, [passwordError]);

    const onChangeHandler = e => {
        switch (e.target.name) {
            case 'password':
                setPasswordValue(e.target.value);
                return;
            case 'country':
                setCountryValue(e.target.value);
                return;
            case 'address':
                setAddressValue(e.target.value);
                return;
            case 'postalCode':
                setPostalCodeValue(e.target.value);
                return;
            case 'city':
                setCityValue(e.target.value);
                return;
            default:
                return;
        }
    };

    const onClickButtonHandler = e => {
        // TODO condition error
        if (passwordError.length <= 0) {
            finalizeAccount({
                NoCli: user.NoCli,
                email: user.email,
                passwd: passwordValue,
                Adresse: addressValue,
                CodePostal: postalCodeValue,
                Ville: cityValue,
                Pays: countryValue
            });
        }
    };

    useEffect(() => {
        setTitle('Terminer la configuration de votre compte');
        setSubTitle('Commencez à regarder Cheval TV pour 4,99€ / mois');
    }, [setTitle, setSubTitle]);

    useEffect(() => {
        if (passwordValue.length > 0 && passwordValue.length < 5) {
            setPasswordError('Le mot de passe doit contenir minimum 5 caractères');
        } else {
            setPasswordError('');
        }
    }, [passwordValue]);

    return (
        <>
            <Input
                label="Mot de passe*"
                name="password"
                onChange={onChangeHandler}
                type="password"
                value={passwordValue}
                error={passwordError}
            />
            <Input
                label="Pays"
                name="country"
                onChange={onChangeHandler}
                type="text"
                value={countryValue}
            />
            <Input
                label="Adresse"
                name="address"
                onChange={onChangeHandler}
                type="text"
                value={addressValue}
            />
            <Input
                label="Code postal"
                name="postalCode"
                onChange={onChangeHandler}
                type="text"
                value={postalCodeValue}
            />
            <Input
                label="Ville"
                name="city"
                onChange={onChangeHandler}
                type="text"
                value={cityValue}
            />
            <button className="button" onClick={onClickButtonHandler} disabled={buttonDisabled}>
                Je crée mon compte
            </button>
            {/* <p className="main__text">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
                tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero
                eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet,
                consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
                dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo
                dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem
                ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
                voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd
                gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor
                sit amet, consetetur
            </p> */}
        </>
    );
};

export default Registration;
