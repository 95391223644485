import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useAuth from 'hooks/useAuth';

const Home = ({ setTitle, setSubTitle, emailValue, emailError }) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const { createUser } = useAuth();
    const navigate = useNavigate();

    const buttonDisabled = useMemo(() => {
        if (errorMessage) setErrorMessage('');
        return emailValue?.length <= 0 || emailError?.length > 0;
    }, [emailValue, emailError, errorMessage]);

    const onClickCreateHandler = e => navigate('/creation');

    const onClickLoginHandler = () => navigate('/connexion');

    useEffect(() => {
        setTitle('Veuillez vous identifier pour continuer');
        setSubTitle('');
    }, [setTitle, setSubTitle]);

    return (
        <>
            <button className="button" onClick={onClickLoginHandler}>
                Se connecter
            </button>
            <button className="button" onClick={onClickCreateHandler}>
                Créer un compte
            </button>
        </>
    );
};

export default Home;
