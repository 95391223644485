import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useAuth from 'hooks/useAuth';

const Reactivate = () => {
    const [errorMessage, setErrorMessage] = useState('');
    const { reactivateSubscription } = useAuth();
    const navigate = useNavigate();

    const onClickUnsubscribeHandler = () => {
        reactivateSubscription().then(res => {
            if (!res)
                setErrorMessage(
                    'Une erreur est survenue lors de la réactivation de votre abonnement.'
                );
            else navigate('/profil/abonnement');
        });
    };

    return (
        <>
            <h1 className="profile__content__title">Réactiver mon abonnement</h1>
            <button className="button" onClick={onClickUnsubscribeHandler}>
                Réactiver mon abonnement
            </button>
            {errorMessage.length > 0 && (
                <div className="main__text main__text--error">{errorMessage}</div>
            )}
        </>
    );
};

export default Reactivate;
