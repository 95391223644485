import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import useAuth from 'hooks/useAuth';
import Input from 'components/Input';
import Footer from 'components/Footer';

const navigationItems = [
    { text: 'Mon compte', path: '/profil' },
    { text: 'Abonnement', path: '/profil/abonnement' },
    { text: 'Se déconnecter', path: '/profil/deconnexion' }
];

const Profile = ({ children: Children, menuItemActive }) => {
    const { user, userValidated, userSubscribed, isAuthenticated } = useAuth();
    const navigate = useNavigate();

    const onClickLogoHandler = e => navigate('/');

    return (
        <>
            <div className="profile">
                <div className="profile__header">
                    <div className="profile__logo">
                        <img
                            src={require('assets/img/Logo-Cheval_NOIR.png')}
                            alt="Logo Cheval TV"
                            onClick={onClickLogoHandler}
                        />
                    </div>
                </div>

                <div className="profile__content">
                    <div className="profile__content__navigation">
                        {navigationItems.map((item, i) => (
                            <div
                                key={i}
                                className={
                                    'profile__content__navigation__item' +
                                    (menuItemActive === item.path
                                        ? ' profile__content__navigation__item--active'
                                        : '')
                                }
                                onClick={() => navigate(item.path)}
                            >
                                {item.text}
                            </div>
                        ))}
                    </div>
                    <div className="profile__content__data">
                        <Children />
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

Profile.propTypes = {
    showEmail: PropTypes.bool
};

Profile.defaultProps = {
    showEmail: true
};

export default Profile;
