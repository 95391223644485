import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import useAuth from 'hooks/useAuth';
import Page from 'components/Page';
import Profile from 'components/Profile';
import CreateAcccount from 'components/CreateAcccount';
import Registration from 'components/Registration';
import Subscription from 'components/Subscription';
import Success from 'components/Success';
import Login from 'components/Login';
import Privacy from 'components/Privacy';
import Legal from 'components/Legal';
import CGU from 'components/CGU';
import Account from 'components/Account';
import AccountSubscription from 'components/AccountSubscription';
import Logout from 'components/Logout';
import DeleteAccount from 'components/DeleteAccount';
import Unsubscribe from 'components/Unsubscribe';
import Reactivate from 'components/Reactivate';
import Home from 'components/Home';
import Pairing from 'components/Pairing';

const Layout = ({ content, ...restProps }) => <Page {...restProps}>{content}</Page>;
const ProfileLayout = ({ content, ...restProps }) => <Profile {...restProps}>{content}</Profile>;

const Router = () => {
    const { isInitialized, isAuthenticated } = useAuth();
    console.log(`init ${isInitialized}, auth ${isAuthenticated}`);
    return !isInitialized ? (
        <></>
    ) : (
        <BrowserRouter>
            <Routes>
                <Route
                    path="/confidentialite"
                    element={<Layout content={Privacy} showEmail={false} />}
                />
                <Route
                    path="/mentions-legales"
                    element={<Layout content={Legal} showEmail={false} />}
                />
                <Route path="/cgu" element={<Layout content={CGU} showEmail={false} />} />
                {!isAuthenticated ? (
                    <>
                        <Route path="/connexion" element={<Layout content={Login} />} />
                        <Route path="/creation" element={<Layout content={CreateAcccount} />} />
                        <Route
                            path="/pairing"
                            element={<Layout content={Home} showEmail={false} />}
                        />
                        <Route path="/" element={<Layout content={Home} showEmail={false} />} />
                    </>
                ) : (
                    <>
                        <Route path="/inscription" element={<Layout content={Registration} />} />
                        <Route path="/abonnement" element={<Layout content={Subscription} />} />
                        <Route path="/succes" element={<Layout content={Success} />} />
                        <Route path="/pairing" element={<Layout content={Pairing} />} />
                        <Route
                            path="/profil/abonnement"
                            element={
                                <ProfileLayout
                                    content={AccountSubscription}
                                    menuItemActive="/profil/abonnement"
                                />
                            }
                        />
                        <Route
                            path="/profil/deconnexion"
                            element={
                                <ProfileLayout
                                    content={Logout}
                                    menuItemActive="/profil/deconnexion"
                                />
                            }
                        />
                        <Route
                            path="/profil/suppression"
                            element={
                                <ProfileLayout content={DeleteAccount} menuItemActive="/profil" />
                            }
                        />
                        <Route
                            path="/profil/desabonnement"
                            element={
                                <ProfileLayout content={Unsubscribe} menuItemActive="/profil" />
                            }
                        />
                        <Route
                            path="/profil/reabonnement"
                            element={
                                <ProfileLayout content={Reactivate} menuItemActive="/profil" />
                            }
                        />
                        <Route
                            path="/profil"
                            element={<ProfileLayout content={Account} menuItemActive="/profil" />}
                        />
                        <Route path="/pairing" element={<Layout content={Pairing} />} />
                        <Route path="/" element={<Navigate to="/pairing" replace />} />
                    </>
                )}
                {/* <Route path="/" element={<Layout content={Home} showEmail={false} />} /> */}
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        </BrowserRouter>
    );
};

export default Router;
