import React, { useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import StripeCardForm from 'components/StripeCardForm';

const STRIPE_PK_TEST =
    'pk_test_51KvzElGao13aj5Cg8u6epDImdjiIyxrovXoAp3bCHHL21e84E7I4HkzKYW3WNPy0LYdP3fuXHtNR6UbtRQizdVZL00uvoMzLWj';
const STRIPE_PK_LIVE =
    'pk_live_51KvzElGao13aj5CgDM3Lge2Of4xhcXiAlCL5sRffbZamxcYUxw1svpHSgc0VIV0Q8g5fmKeMB5J6VN0OtsY2SIVD00snTimwQY';
const stripePromise = loadStripe(STRIPE_PK_LIVE);

const Subscription = ({ setTitle, setSubTitle }) => {
    useEffect(() => {
        setTitle('Commencez à regarder Cheval TV sur Amazon Fire TV pour 4,99€ / mois');
        setSubTitle(
            'La résiliation est possible à tout moment et prend effet à la fin de la période de facturation'
        );
    }, [setTitle, setSubTitle]);

    return (
        <Elements stripe={stripePromise}>
            <StripeCardForm />
            <p className="main__text">
                En confirmant votre abonnement, vous autorisez Cheval TV à débiter votre carte pour
                ce paiement et les paiements futurs conformément à ses conditions.
            </p>
        </Elements>
    );
};

export default Subscription;
