import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useAuth from 'hooks/useAuth';

const CreateAcccount = ({ setTitle, setSubTitle, emailValue, emailError }) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const { createUser } = useAuth();
    const navigate = useNavigate();

    const buttonDisabled = useMemo(() => {
        if (errorMessage) setErrorMessage('');
        return emailValue?.length <= 0 || emailError?.length > 0;
    }, [emailValue, emailError, errorMessage]);

    const onClickCreateHandler = e => {
        if (emailError.length <= 0) {
            createUser(emailValue)
                .then(res => {
                    if (!res.ret) throw new Error();

                    if (!res.userCreated) {
                        setErrorMessage('Cet e-mail est déja utilisé. Veuillez vous connecter.');
                    } else
                        setSuccessMessage(
                            'Pour poursuivre votre inscription veuillez suivre le lien qui vous a été envoyé par mail.'
                        );
                })
                .catch(err => setErrorMessage('Une erreur est survenue.'));
        }
    };

    const onClickLoginHandler = () => {
        navigate('/connexion');
    };

    useEffect(() => {
        setTitle('Entrez votre e-mail pour créer un compte');
        setSubTitle('');
    }, [setTitle, setSubTitle]);

    return (
        <>
            {errorMessage.length > 0 && (
                <div className="main__text main__text--error" style={{ marginBottom: '0.5rem' }}>
                    {errorMessage}
                </div>
            )}
            {successMessage.length > 0 && (
                <div className="main__text main__text--success" style={{ marginBottom: '0.5rem' }}>
                    {successMessage}
                </div>
            )}
            <button className="button" onClick={onClickCreateHandler} disabled={buttonDisabled}>
                Créer un compte
            </button>
            <div className="main__text main__text--center main__link" onClick={onClickLoginHandler}>
                J'ai déja un compte
            </div>
        </>
    );
};

export default CreateAcccount;
