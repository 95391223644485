import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useAuth from 'hooks/useAuth';
import Input from 'components/Input';

const DeleteAccount = () => {
    const [passwordValue, setPasswordValue] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const { deleteUser, user } = useAuth();
    const navigate = useNavigate();

    const buttonDisabled = useMemo(() => passwordValue.length < 5, [passwordValue]);

    const onClickDeleteHandler = () => {
        deleteUser({
            NoCli: user.NoCli,
            passwd: passwordValue
        }).then(res => {
            if (!res) setErrorMessage('Une erreur est survenue lors de la suppression');
            else navigate('/');
        });
    };

    const onChangePasswordsHandler = e => {
        setPasswordValue(e.target.value);
    };

    return (
        <>
            <h1 className="profile__content__title">Supprimer mon compte</h1>
            <div className="main__text" style={{ marginBottom: '1rem' }}>
                Nous sommes navrés de vous voir partir. La suppression de votre compte entrainera la
                suppression de l'ensemble de vos données. Si vous êtes abonné(e), la suppression de
                votre compte entrainera la suppression immédiate de votre abonnement.
            </div>
            <div className="main__text" style={{ marginBottom: '1rem' }}>
                Entrer votre mot de passe pour confirmer la suppression de votre compte
            </div>
            <Input
                label="Mot de passe"
                name="passwd"
                onChange={onChangePasswordsHandler}
                type="password"
                placeholder="********"
                value={passwordValue}
            />
            <button className="button" onClick={onClickDeleteHandler} disabled={buttonDisabled}>
                Supprimer mon compte
            </button>
            {errorMessage.length > 0 && (
                <div className="main__text main__text--error">{errorMessage}</div>
            )}
        </>
    );
};

export default DeleteAccount;
