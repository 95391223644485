import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';

import useAuth from 'hooks/useAuth';
import Input from 'components/Input';
import Footer from 'components/Footer';
import { ReactComponent as IconProfile } from 'assets/icons/icon-profile.svg';
//eslint-disable-next-line
const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

const Page = ({ children: Children, showEmail }) => {
    const [title, setTitle] = useState('');
    const [subTitle, setSubTitle] = useState('');
    const [emailValue, setEmailValue] = useState('');
    const [emailError, setEmailError] = useState('');
    const { user, userValidated, userSubscribed, isAuthenticated } = useAuth();
    const navigate = useNavigate();

    const onChangeHandler = e => {
        const { value } = e.target;
        if (!emailRegex.test(value)) setEmailError('E-mail invalide');
        else setEmailError('');
        setEmailValue(value);
    };

    const onClickLogoHandler = e => {
        navigate('/');
    };

    useEffect(() => {
        if (!user?.email || !showEmail) return;
        if (!userValidated) {
            navigate('/inscription');
        } else if (!userSubscribed) {
            navigate('/abonnement');
        } else {
            navigate('/succes');
        }
        setEmailValue(user?.email || '');
    }, [user, userValidated, userSubscribed, navigate]);

    return (
        <>
            <div className="main">
                {isAuthenticated && (
                    <Link to="/profil" className="account">
                        <div className="account__logo">
                            <IconProfile />
                        </div>
                        <div className="account__text">Mon compte</div>
                    </Link>
                )}
                <div className="main__header">
                    <div className="main__logo">
                        <img
                            src={require('assets/img/Logo-Cheval_NOIR.png')}
                            alt="Logo Cheval TV"
                            onClick={onClickLogoHandler}
                        />
                        <span className="main__logo__text">sur Amazon Fire TV</span>
                    </div>
                    <h1 className="main__title">{title}</h1>
                    <p className="main__subtitle">{subTitle}</p>
                </div>
                <div className="main__content">
                    {showEmail && !userSubscribed && (
                        <Input
                            disabled={!!user?.email}
                            label="E-mail"
                            name="email"
                            onChange={onChangeHandler}
                            placeholder="cheval@example.fr"
                            type="email"
                            value={emailValue}
                            error={emailError}
                        />
                    )}
                    <Children
                        setTitle={setTitle}
                        setSubTitle={setSubTitle}
                        emailValue={emailValue}
                        emailError={emailError}
                        setEmailError={setEmailError}
                    />
                </div>
            </div>
            <Footer />
        </>
    );
};

Page.propTypes = {
    showEmail: PropTypes.bool
};

Page.defaultProps = {
    showEmail: true
};

export default Page;
