import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import useAuth from 'hooks/useAuth';

const AccountSubscription = () => {
    const { user, userSubscribed } = useAuth();
    const navigate = useNavigate();

    const getDisplayableDate = ts => {
        const date = new Date(ts * 1000);
        const padTo2Digits = num => num.toString().padStart(2, '0');

        return [
            padTo2Digits(date.getDate()),
            padTo2Digits(date.getMonth() + 1),
            date.getFullYear()
        ].join('/');
    };

    const subscriptionDisplayDate = useMemo(
        () => getDisplayableDate(user?.date_abo) || '',
        [user?.date_abo]
    );

    const nextPaymentDisplayDate = useMemo(
        () => getDisplayableDate(user?.date_next_payment) || '',
        [user?.date_next_payment]
    );

    const unsubscribeEffectDisplayDate = useMemo(
        () => getDisplayableDate(user?.date_desabo) || '',
        [user?.date_desabo]
    );

    const onClickSubscribeHandler = () => {
        navigate('/abonnement');
    };

    const onClickUnsubscribeHandler = () => {
        navigate('/profil/desabonnement');
    };

    const onClickReactivateHandler = () => {
        navigate('/profil/reabonnement');
    };

    console.log('us', user);
    return (
        <>
            <h1 className="profile__content__title">Abonnement</h1>
            {userSubscribed ? (
                <>
                    <div className="profile__subscription__section">
                        <div className="profile__subscription__section__title">
                            Date de souscription
                        </div>
                        <div className="profile__subscription__section__text">
                            {subscriptionDisplayDate}
                        </div>
                    </div>
                    {user?.date_desabo <= 0 ? (
                        <div className="profile__subscription__section">
                            <div className="profile__subscription__section__title">
                                Prochain prélèvement
                            </div>
                            <div className="profile__subscription__section__text">
                                {nextPaymentDisplayDate}
                            </div>
                        </div>
                    ) : (
                        <div className="profile__subscription__section">
                            <div className="profile__subscription__section__title">
                                Fin de l'abonnement
                            </div>
                            <div className="profile__subscription__section__text">
                                {unsubscribeEffectDisplayDate}
                            </div>
                        </div>
                    )}
                    <div className="separator" />
                    {user?.date_desabo <= 0 ? (
                        <div className="profile__content__link" onClick={onClickUnsubscribeHandler}>
                            Se désabonner
                        </div>
                    ) : (
                        <div className="profile__content__link" onClick={onClickReactivateHandler}>
                            Réactiver mon abonnement
                        </div>
                    )}
                </>
            ) : (
                <>
                    <div className="main__text" style={{ marginBottom: '1rem' }}>
                        Commencez à regarder CHEVAL TV sur Amazon Fire TV pour 4,99€/mois.
                    </div>
                    <button className="button" onClick={onClickSubscribeHandler}>
                        S'abonner
                    </button>
                </>
            )}
        </>
    );
};

export default AccountSubscription;
