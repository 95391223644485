import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

const Footer = () => {
    const { isAuthenticated } = useAuth();

    const authenticatedMenuItems = [{ text: 'Mon compte', path: '/profil' }];

    const anonymousMenuItems = [{ text: 'Connexion', path: '/connexion' }];

    const commonMenuItems = [
        { text: 'Déclaration de confidentialité', path: '/confidentialite' },
        { text: 'Mentions légales', path: '/mentions-legales' },
        { text: 'CGU', path: '/cgu' }
    ];

    const renderMenuItem = (item, i) => (
        <li key={i} className="footer__nav__item">
            <Link to={item.path} className="footer__nav__item__link">
                {item.text}
            </Link>
        </li>
    );

    return (
        <footer className="footer">
            <nav>
                <ul className="footer__nav">
                    {isAuthenticated
                        ? authenticatedMenuItems.map((item, i) => renderMenuItem(item, i))
                        : anonymousMenuItems.map((item, i) => renderMenuItem(item, i))}
                    {commonMenuItems.map((item, i) => renderMenuItem(item, i))}
                </ul>
            </nav>
        </footer>
    );
};

export default Footer;
