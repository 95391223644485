import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useAuth from 'hooks/useAuth';

const Unsubscribe = () => {
    const [errorMessage, setErrorMessage] = useState('');
    const { unsubscribe } = useAuth();
    const navigate = useNavigate();

    const onClickUnsubscribeHandler = () => {
        unsubscribe().then(res => {
            if (!res) setErrorMessage('Une erreur est survenue lors du désabonnement');
            else navigate('/profil/abonnement');
        });
    };

    return (
        <>
            <h1 className="profile__content__title">Se désabonner</h1>
            <div className="main__text" style={{ marginBottom: '1rem' }}>
                Nous sommes navrés de vous voir partir. Le désabonnement sera effectif au terme de
                votre abonnement actuel. Aucun nouveau prélèvement ne sera effectué.
            </div>
            <button className="button" onClick={onClickUnsubscribeHandler}>
                Se désabonner
            </button>
            {errorMessage.length > 0 && (
                <div className="main__text main__text--error">{errorMessage}</div>
            )}
        </>
    );
};

export default Unsubscribe;
