import React, { useState, createRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as IconVisibilityOn } from 'assets/icons/icon-visibility-on.svg';
import { ReactComponent as IconVisibilityOff } from 'assets/icons/icon-visibility-off.svg';

const Input = ({
    className,
    label,
    name,
    onChange,
    error,
    type,
    inlineValidation,
    onClickValidate,
    validateButtonText,
    withValidation,
    ...restProps
}) => {
    const [value, setValue] = useState('');
    const [showValue, setShowValue] = useState(false);
    const inputRef = createRef();

    const typeActive = useMemo(() => {
        if (type !== 'password') return type;
        return showValue ? 'text' : 'password';
    }, [type, showValue]);

    const inputOnChangeHandler = event => {
        if (typeof onChange === 'function') {
            onChange(event);
        }
        setValue(event.target.value);
    };

    const onClickVisibilityOnHandler = e => {
        if (restProps.disabled) return;
        setShowValue(true);
    };

    const onClickVisibilityOffHandler = e => {
        if (restProps.disabled) return;
        setShowValue(false);
    };

    return (
        <div className="form__input">
            {label?.length > 0 && <label htmlFor={`inp-${name}`}>{label}</label>}
            <div className={'input__wrapper' + (inlineValidation ? ' input__wrapper--inline' : '')}>
                <input
                    autoComplete="off"
                    id={`inp-${name}`}
                    name={name}
                    onChange={inputOnChangeHandler}
                    ref={inputRef}
                    value={value}
                    type={typeActive}
                    {...restProps}
                />
                {type === 'password' &&
                    (showValue ? (
                        <IconVisibilityOff
                            className={
                                'icon icon--eye' + (restProps.disabled ? ' icon--disabled' : '')
                            }
                            onClick={onClickVisibilityOffHandler}
                        />
                    ) : (
                        <IconVisibilityOn
                            className={
                                'icon icon--eye' + (restProps.disabled ? ' icon--disabled' : '')
                            }
                            onClick={onClickVisibilityOnHandler}
                        />
                    ))}
                {inlineValidation && (
                    <button className="button" onClick={onClickValidate}>
                        {validateButtonText}
                    </button>
                )}
            </div>
            {error?.length > 0 && <div className="form__input__error">{error}</div>}
        </div>
    );
};

Input.propTypes = {
    className: PropTypes.string,
    error: PropTypes.string,
    inlineValidation: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    onClickValidate: PropTypes.func,
    placeholder: PropTypes.string,
    tabIndex: PropTypes.number,
    type: PropTypes.string,
    value: PropTypes.string.isRequired,
    withValidation: PropTypes.bool
};

Input.defaultProps = {
    error: '',
    inlineValidation: false,
    validateButtonText: 'Appliquer',
    withValidation: false
};

export default Input;
