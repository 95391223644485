import React from 'react';
import { useNavigate } from 'react-router-dom';

import useAuth from 'hooks/useAuth';

const Logout = () => {
    const { logout } = useAuth();
    const navigate = useNavigate();

    const onClickLogoutHandler = () => {
        logout();
    };

    const onClickCancelHandler = () => navigate('/profil');

    return (
        <>
            <h1 className="profile__content__title">Se déconnecter</h1>
            <div className="main__text" style={{ marginBottom: '1rem' }}>
                Êtes-vous sur de vouloir vous déconnecter ?
            </div>
            <button className="button" onClick={onClickLogoutHandler}>
                Se déconnecter
            </button>
        </>
    );
};

export default Logout;
