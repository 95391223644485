import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useAuth from 'hooks/useAuth';
import Input from 'components/Input';

const Account = () => {
    const [errorMessage, setErrorMessage] = useState('');
    const [passwordsError, setPasswordsError] = useState('');
    const [passwordsSuccess, setPasswordsSuccess] = useState('');
    const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
    const [passwordActive, setPasswordActive] = useState(false);
    const [passwordValue, setPasswordValue] = useState('');
    const [newPasswordValue, setNewPasswordValue] = useState('');
    const [countryValue, setCountryValue] = useState('');
    const [addressValue, setAddressValue] = useState('');
    const [postalCodeValue, setPostalCodeValue] = useState('');
    const [cityValue, setCityValue] = useState('');

    const { changeUserPassword, deleteUser, updateUser, user } = useAuth();
    const navigate = useNavigate();

    const buttonDisabled = useMemo(() => {
        return !(
            user?.Pays !== countryValue ||
            user?.Adresse !== addressValue ||
            user?.CodePostal !== postalCodeValue ||
            user?.Ville !== cityValue
        );
    }, [user, countryValue, addressValue, postalCodeValue, cityValue]);

    const onClickSaveHandler = () => {
        if (errorMessage.length > 0) setErrorMessage('');

        updateUser({
            NoCli: user.NoCli,
            Pays: countryValue,
            Adresse: addressValue,
            CodePostal: postalCodeValue,
            Ville: cityValue
        }).then(res => {
            if (!res) setErrorMessage('Une erreur est survenue');
        });
    };

    const onChangePasswordsHandler = e => {
        switch (e.target.name) {
            case 'passwd':
                setPasswordValue(e.target.value);
                return;
            case 'new-passwd':
                setNewPasswordValue(e.target.value);
                return;
            default:
                return;
        }
    };

    const onChangeHandler = e => {
        switch (e.target.name) {
            case 'country':
                setCountryValue(e.target.value);
                return;
            case 'address':
                setAddressValue(e.target.value);
                return;
            case 'postalCode':
                setPostalCodeValue(e.target.value);
                return;
            case 'city':
                setCityValue(e.target.value);
                return;
            default:
                return;
        }
    };

    const onClickDeleteHandler = () => {
        navigate('/profil/suppression');
    };

    const onClickChangePswdHandler = () => {
        setPasswordActive(true);
    };

    const onClickCancelChangePswdHandler = () => {
        setPasswordErrorMessage('');
        setPasswordValue('');
        setNewPasswordValue('');
        setPasswordsError('');
        setPasswordsSuccess('');
        setPasswordActive(false);
    };

    const onClickConfirmChangePswdHandler = () => {
        changeUserPassword({
            NoCli: user.NoCli,
            passwd: passwordValue,
            newPasswd: newPasswordValue
        }).then(res => {
            switch (res.data) {
                case 'Wrong password':
                    setPasswordsError('Mot de passe incorrect');
                    break;
                case 'Password updated':
                    setPasswordsSuccess('Mot de passe mis à jour avec succès');
                    setPasswordErrorMessage('');
                    setPasswordsError('');
                    setPasswordValue('');
                    setNewPasswordValue('');
                    setPasswordActive(false);
                    break;
                default:
                    setPasswordsError('Une erreur est survenue');
            }
        });
    };

    useEffect(() => {
        if (newPasswordValue.length <= 0) return;
        else if (newPasswordValue.length < 5) {
            setPasswordErrorMessage('Le mot de passe doit contenir minimum 5 caractères');
        } else {
            setPasswordErrorMessage('');
        }
    }, [newPasswordValue]);

    useEffect(() => {
        setCountryValue(user?.Pays || '');
        setAddressValue(user?.Adresse || '');
        setPostalCodeValue(user?.CodePostal || '');
        setCityValue(user?.Ville || '');
    }, [user]);

    return (
        <>
            <h1 className="profile__content__title">Mon compte</h1>
            <Input disabled label="E-mail" name="email" type="email" value={user?.email || ''} />
            <Input
                disabled={!passwordActive}
                label="Mot de passe"
                name="passwd"
                onChange={onChangePasswordsHandler}
                type="password"
                placeholder="********"
                value={passwordValue}
            />
            {passwordActive && (
                <Input
                    label="Nouveau mot de passe"
                    name="new-passwd"
                    onChange={onChangePasswordsHandler}
                    type="password"
                    placeholder="********"
                    value={newPasswordValue}
                    error={passwordErrorMessage}
                />
            )}
            {!passwordActive ? (
                <div className="main__link" onClick={onClickChangePswdHandler}>
                    Changer mon mot de passe
                </div>
            ) : (
                <>
                    <div className="button" onClick={onClickConfirmChangePswdHandler}>
                        Sauvegarder
                    </div>
                    <div
                        className="main__link main__text--center"
                        onClick={onClickCancelChangePswdHandler}
                    >
                        Annuler
                    </div>
                </>
            )}
            {passwordsError.length > 0 && (
                <div className="main__text main__text--error">{passwordsError}</div>
            )}
            {passwordsSuccess.length > 0 && (
                <div className="main__text main__text--success">{passwordsSuccess}</div>
            )}
            <div className="separator" />
            <Input
                label="Pays"
                name="country"
                onChange={onChangeHandler}
                type="text"
                value={countryValue}
            />
            <Input
                label="Adresse"
                name="address"
                onChange={onChangeHandler}
                type="text"
                value={addressValue}
            />
            <Input
                label="Code postal"
                name="postalCode"
                onChange={onChangeHandler}
                type="text"
                value={postalCodeValue}
            />
            <Input
                label="Ville"
                name="city"
                onChange={onChangeHandler}
                type="text"
                value={cityValue}
            />
            <button className="button" onClick={onClickSaveHandler} disabled={buttonDisabled}>
                Sauvegarder
            </button>
            {errorMessage.length > 0 && (
                <div className="main__text main__text--center main__text--error">
                    {errorMessage}
                </div>
            )}
            <div className="profile__content__link" onClick={onClickDeleteHandler}>
                Supprimer mon compte
            </div>
        </>
    );
};

export default Account;
