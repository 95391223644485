import React, { useEffect } from 'react';

const CGUStyle = () => (
    <style type="text/css">{`
        p.p1 {
            margin: 0.0px 0.0px 0.0px 0.0px;
            font: 12.0px
        }

        p.p2 {
            margin: 0.0px 0.0px 0.0px 0.0px;
            font: 12.0px;
            min-height: 14.0px
        }

        p.p3 {
            margin: 0.0px 0.0px 30.0px 0.0px;
            font: 24.0px;
            color: #1d1d1b
        }

        p.p4 {
            margin: 0.0px 0.0px 0.0px 0.0px;
            font: 13.0px;
            color: #1d1d1b
        }

        p.p5 {
            margin: 0.0px 0.0px 0.0px 0.0px;
            font: 24.0px;
            color: #1d1d1b
        }

        p.p6 {
            margin: 5.0px 0.0px 5.0px 0.0px;
            font: 13.0px;
            color: #1d1d1b
        }

        p.p7 {
            margin: 0.0px 0.0px 0.0px 0.0px;
            font: 13.0px;
            color: #1d1d1b;
            min-height: 15.0px
        }

        li.li4 {
            margin: 0.0px 0.0px 0.0px 0.0px;
            font: 13.0px;
            color: #1d1d1b
        }

        span.s1 {
            color: #000000
        }

        ul.ul1 {
            list-style-type: disc
        }
        p.p1 {
            margin: 0.0px 0.0px 0.0px 0.0px;
            font: 12.0px;
            min-height: 14.0px
        }

        p.p2 {
            margin: 0.0px 0.0px 30.0px 0.0px;
            font: 24.0px;
            color: #1d1d1b
        }

        p.p3 {
            margin: 0.0px 0.0px 0.0px 0.0px;
            font: 13.0px;
            color: #1d1d1b
        }

        p.p4 {
            margin: 0.0px 0.0px 0.0px 0.0px;
            font: 24.0px;
            color: #1d1d1b
        }

        p.p5 {
            margin: 0.0px 0.0px 0.0px 0.0px;
            font: 13.5px;
            color: #1d1d1b
        }

        p.p6 {
            margin: 0.0px 0.0px 0.0px 0.0px;
            font: 13.0px;
            color: #1d1d1b;
            min-height: 15.0px
        }
        
        p.p1 {
            margin: 0.0px 0.0px 0.0px 0.0px;
            font: 24.0px;
            color: #1d1d1b
        }

        p.p2 {
            margin: 0.0px 0.0px 0.0px 0.0px;
            font: 13.0px;
            color: #1d1d1b
        }

        p.p3 {
            margin: 5.0px 0.0px 5.0px 0.0px;
            font: 13.0px;
            color: #1d1d1b
        }

        p.p4 {
            margin: 0.0px 0.0px 0.0px 0.0px;
            font: 13.0px;
            color: #1d1d1b;
            min-height: 15.0px
        }

        p.p5 {
            margin: 0.0px 0.0px 0.0px 0.0px;
            font: 13.0px;
            color: #0000ff
        }

        p.p6 {
            margin: 0.0px 0.0px 0.0px 0.0px;
            font: 13.0px;
            color: #0000ff;
            min-height: 15.0px
        }

        li.li2 {
            margin: 0.0px 0.0px 0.0px 0.0px;
            font: 13.0px;
            color: #1d1d1b
        }

        span.s1 {
            color: #000000
        }

        span.s2 {
            text-decoration: underline
        }

        ul.ul1 {
            list-style-type: disc
        }
    `}</style>
);

const CGU = ({ setTitle, setSubTitle }) => {
    useEffect(() => {
        setTitle('');
        setSubTitle('');
    }, [setTitle, setSubTitle]);

    return (
        <>
            <CGUStyle />
            <p class="p1">Conditions G&eacute;n&eacute;rales d&rsquo;Utilisation (CGU)</p>
            <p class="p2">&nbsp;</p>
            <p class="p3">
                Les pr&eacute;sentes Conditions G&eacute;n&eacute;rales d&rsquo;Utilisation
                (ci-apr&egrave;s &laquo; CGU &raquo;) ont pour objectif de d&eacute;finir les
                conditions dans lesquelles l&rsquo;Utilisateur peut souscrire et utiliser le Service
                CHEVAL TV, &eacute;dit&eacute; et propos&eacute; par la soci&eacute;t&eacute;
                CHEVALTV Soci&eacute;t&eacute; par Actions Simplifi&eacute;e au capital de 10 000
                euros, immatricul&eacute;e au Registre du Commerce et des Soci&eacute;t&eacute;s de
                Melun sous le num&eacute;ro{' '}
                <span class="s1">
                    dont le si&egrave;ge social se trouve au 52 rue de la for&ecirc;t
                    <span class="Apple-converted-space">&nbsp;&nbsp;</span>77630
                    Saint-Martin-en-Bri&egrave;re, et repr&eacute;sent&eacute;e par son
                    Pr&eacute;sident Monsieur Jean Morel&nbsp;.
                </span>
            </p>
            <p class="p2">
                CHECAL TV est un service de vid&eacute;o &agrave; la demande par abonnement
                fournissant &agrave; ses utilisateurs l&apos;acc&egrave;s &agrave; des films
                diffus&eacute;s en streaming par Internet sur les ordinateurs,
                t&eacute;l&eacute;viseurs connect&eacute;s, tablettes et smartphones.
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">Le directeur de publication&nbsp;est Monsieur Jean MOREL.</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">Le Site est h&eacute;berg&eacute; par&nbsp;OVH.</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">1. D&Eacute;FINITIONS</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                Dans les CGU les termes d&eacute;finis ci-dessous auront la signification suivante :
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                &laquo; Abonn&eacute; &raquo; : d&eacute;signe une personne ayant atteint la
                majorit&eacute; l&eacute;gale sur le territoire sur lequel elle vit et ayant
                souscrit un abonnement au Service propos&eacute; par la Soci&eacute;t&eacute; ;
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                &laquo; Membre &raquo; : utilisateur s&rsquo;&eacute;tant inscrit sur le Site
                internet ;
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                &laquo; Service &raquo; : d&eacute;signe le Service d&apos;abonnement
                intitul&eacute; &laquo; CHEVAL TV &raquo;, accessible sur le Site internet aux
                Abonn&eacute;s et proposant notamment le visionnage en streaming ;
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                &laquo; Site Internet &raquo; : d&eacute;signe le site web &lt;www.chevaltv.fr&gt; ;
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                &laquo; Soci&eacute;t&eacute; &raquo; : d&eacute;signe la soci&eacute;t&eacute;
                CHEVAL TV &nbsp;;
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                &laquo; SVOD &raquo; : Service de vid&eacute;o &agrave; la demande par abonnement ;
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                &laquo; Utilisateur &raquo; : Utilisateur du Service, qu&rsquo;il soit Abonn&eacute;
                ou Membre.
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">2. OBJET</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">Les pr&eacute;sentes CGU ont pour objet de :</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                D&eacute;finir les conditions et modalit&eacute;s encadrant l&rsquo;abonnement au
                Service &laquo; CHEVAL TV &raquo; fourni &agrave; l&rsquo;Abonn&eacute; par la
                Soci&eacute;t&eacute; ;
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">D&eacute;terminer les droits et obligations des Parties.</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                La Soci&eacute;t&eacute; peut apporter toutes modifications qu&apos;elle jugera
                n&eacute;cessaires au Service.
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                L&rsquo;acc&egrave;s au Service n&eacute;cessite l&rsquo;acceptation sans
                r&eacute;serve des pr&eacute;sentes CGU. Si l&rsquo;Utilisateur ne souhaite pas
                &ecirc;tre soumis aux CGU, il n&rsquo;est pas autoris&eacute; &agrave; utiliser le
                Service. La Soci&eacute;t&eacute; se r&eacute;serve la possibilit&eacute; de
                modifier les CGU &agrave; tout moment. Dans ce cas, la nouvelle version des CGU sera
                disponible sur le Site, et l&rsquo;Utilisateur en sera averti par un pop-up sur le
                Site, l&rsquo;invitant &agrave; consulter la nouvelle version des CGU et &agrave;
                les accepter. Les CGU applicables seront alors les derni&egrave;res conditions que
                l&rsquo;Utilisateur aura accept&eacute;es lors de la connexion au Service. Si
                l&rsquo;Utilisateur refuse d&rsquo;accepter une nouvelle version des CGU, il restera
                alors tenu par la version des CGU qu&rsquo;il a accept&eacute; et
                b&eacute;n&eacute;ficiera uniquement des Services qui sont associ&eacute;s &agrave;
                la version des CGU qu&rsquo;il aura accept&eacute; jusqu&rsquo;au terme de son
                abonnement. L&rsquo;Utilisateur n&rsquo;acceptant pas la nouvelle version des CGU
                est tenu de supprimer son compte imm&eacute;diatement ou, s&rsquo;il dispose
                d&rsquo;un abonnement, &agrave; la fin de ce dernier. Il est pr&eacute;cis&eacute;
                que la reconduction tacite de l&rsquo;abonnement ne sera pas propos&eacute;e aux
                Abonn&eacute;s qui n&rsquo;auront pas accept&eacute; la nouvelle version des CGU.
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">3. ENTR&Eacute;E EN VIGUEUR</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                Les pr&eacute;sentes CGU entrent en vigueur &agrave; compter de leur acceptation par
                l&rsquo;Utilisateur, au moment de la cr&eacute;ation de son compte.
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">4. UTILISATION DU SERVICE</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                L&apos;Abonn&eacute; du Service a la possibilit&eacute; de visionner l&apos;ensemble
                des vid&eacute;os en streaming, sans limite, sans publicit&eacute; et en HD.
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">5. MODALIT&Eacute;S D&apos;UTILISATION DU SERVICE</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">a. MODALIT&Eacute;S D&rsquo;ACC&Egrave;S AU SERVICE</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                Le Service est accessible sur l&rsquo;application CHEVAL TV disponible sur Amazon
                Fire TV.
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                L&rsquo;acc&egrave;s au Service est conditionn&eacute; &agrave; la cr&eacute;ation
                pr&eacute;alable d&rsquo;un compte personnel.
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                Lors de la cr&eacute;ation de ce compte, l&rsquo;Utilisateur devra renseigner les
                informations suivantes&nbsp;:
            </p>
            <p class="p2">&nbsp;</p>
            <ul class="ul1">
                <li class="li2">Email.</li>
            </ul>
            <p class="p2">&nbsp;</p>
            <ul class="ul1">
                <li class="li2">Mot de passe.</li>
            </ul>
            <p class="p2">&nbsp;</p>
            <ul class="ul1">
                <li class="li2">Adresse.</li>
            </ul>
            <p class="p2">&nbsp;</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                Une fois ces informations renseign&eacute;es, l&rsquo;Utilisateur sera invit&eacute;
                &agrave; lire et accepter les pr&eacute;sentes CGU.
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                L&rsquo;Utilisateur garantit la v&eacute;racit&eacute; et l&rsquo;exactitude des
                informations qu&rsquo;il communique &agrave; la Soci&eacute;t&eacute; par
                l&rsquo;interm&eacute;diaire du formulaire.
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                L&rsquo;identifiant et le mot de passe de l&rsquo;Utilisateur sont personnels et
                confidentiels. L&rsquo;Utilisateur s&rsquo;engage &agrave; ne pas les divulguer et
                &agrave; prendre toutes les mesures n&eacute;cessaires pour pr&eacute;server cette
                confidentialit&eacute;. En cons&eacute;quence, l&rsquo;Utilisateur sera responsable
                de toute activit&eacute; ou action sur son compte. La Soci&eacute;t&eacute;
                n&rsquo;a pas acc&egrave;s aux mots de passe. La Soci&eacute;t&eacute; ne saurait en
                aucun cas &ecirc;tre tenue responsable des cons&eacute;quences pr&eacute;judiciables
                &agrave; l&rsquo;Utilisateur r&eacute;sultant des utilisations illicites,
                frauduleuses ou abusives de son identifiant ou mot de passe par un tiers non
                express&eacute;ment autoris&eacute; par l&rsquo;Utilisateur.
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                Pour pouvoir valablement b&eacute;n&eacute;ficier du Service, l&rsquo;Utilisateur
                d&eacute;clare express&eacute;ment avoir la pleine capacit&eacute; juridique de
                conclure le pr&eacute;sent contrat, c&rsquo;est-&agrave;-dire d&rsquo;avoir la
                majorit&eacute; l&eacute;gale (18 ans en France) et de ne pas &ecirc;tre sous
                tutelle ou curatelle, ou bien &ecirc;tre un mineur &eacute;mancip&eacute;.
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                Les Utilisateurs &acirc;g&eacute;s de moins de 18 ans peuvent acc&eacute;der au Site
                et au Service qui y sont propos&eacute;s sous r&eacute;serve de l&rsquo;acceptation
                des pr&eacute;sentes CGU par l&rsquo;un de ses repr&eacute;sentants l&eacute;gaux.
                Pour ce faire, la Soci&eacute;t&eacute; d&eacute;tecte l&rsquo;&acirc;ge des
                Utilisateurs au moyen de la date de naissance renseign&eacute;e sur le formulaire de
                cr&eacute;ation de compte. Lorsque l&rsquo;&acirc;ge d&eacute;tect&eacute; est
                inf&eacute;rieur &agrave; 18 ans, l&rsquo;Utilisateur devra renseigner
                l&rsquo;adresse de messagerie &eacute;lectronique de l&rsquo;un de ses
                repr&eacute;sentants l&eacute;gaux, afin que ce dernier puisse lire et accepter les
                pr&eacute;sentes CGU, et qu&rsquo;il autorise de mani&egrave;re distincte le
                traitement des donn&eacute;es &agrave; caract&egrave;re personnel de
                l&rsquo;Utilisateur &acirc;g&eacute; de moins de 18 ans. Le courrier
                &eacute;lectronique envoy&eacute; au repr&eacute;sentant l&eacute;gal du mineur
                contiendra un lien hypertexte, lui permettant de prendre connaissance des CGU et de
                valider l&rsquo;abonnement dont b&eacute;n&eacute;ficiera le mineur et de consentir
                au traitement de ses donn&eacute;es &agrave; caract&egrave;re personnel lorsque cela
                est exig&eacute; par la r&egrave;glementation applicable.
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                L&rsquo;Utilisateur n&rsquo;est pas autoris&eacute; &agrave; utiliser le Service
                &agrave; d&rsquo;autres fins que celles mentionn&eacute;es dans les pr&eacute;sentes
                Conditions G&eacute;n&eacute;rales. L&rsquo;utilisation de tout syst&egrave;me ou
                logiciel automatis&eacute; visant &agrave; extraire toutes ou partie des
                donn&eacute;es du Site &agrave; des fins commerciales est interdite. La
                Soci&eacute;t&eacute; se r&eacute;serve le droit d&rsquo;intenter toute action ou
                r&eacute;clamation n&eacute;cessaire &agrave; l&rsquo;effet de faire respecter cette
                interdiction, y compris dans le cadre de poursuites judiciaires.
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">b. PROC&Eacute;DURE D&rsquo;ABONNEMENT</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                L&rsquo;Utilisateur obtiendra sur le Site Internet un formulaire d&rsquo;abonnement
                qu&rsquo;il remplira avec les informations suivantes&nbsp;:
            </p>
            <p class="p2">&nbsp;</p>
            <ul class="ul1">
                <li class="li2">Coordonn&eacute;es bancaires.</li>
            </ul>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                Une fois valid&eacute; le formulaire d&rsquo;abonnement, l&rsquo;Abonn&eacute;
                acceptera les pr&eacute;sentes CGU et sera alors redirig&eacute; vers la page de
                paiement afin de conclure son abonnement au Service.
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">c. REFUS D&rsquo;ABONNEMENT</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                La Soci&eacute;t&eacute; se r&eacute;serve le droit de ne pas valider
                l&rsquo;abonnement pour tout motif l&eacute;gitime, notamment dans
                l&rsquo;hypoth&egrave;se o&ugrave; :
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">Le mode de paiement de l&rsquo;Abonn&eacute; ne serait pas valide.</p>
            <p class="p2">
                La Soci&eacute;t&eacute; constaterait qu&rsquo;il existe un litige relatif au
                paiement d&rsquo;un pr&eacute;c&eacute;dent Abonnement de l&rsquo;Abonn&eacute;.
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">6. DUR&Eacute;E</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                L&rsquo;abonnement est conclu pour une dur&eacute;e de 1 mois (abonnement mensuel).
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                &Agrave; son expiration, l&apos;abonnement est reconduit automatiquement et sera
                factur&eacute; selon des p&eacute;riodicit&eacute;s identiques &agrave; celles de
                l&apos;abonnement arrivant &agrave; expiration. La Soci&eacute;t&eacute; se
                r&eacute;serve le droit de modifier ses tarifs &agrave; tout moment. Dans ce cas,
                l&apos;Utilisateur en sera inform&eacute; lors de la mise &agrave; jour des
                pr&eacute;sentes Conditions G&eacute;n&eacute;rales d&apos;Utilisation.
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                L&rsquo;Abonn&eacute; peut s&rsquo;opposer &agrave; cette reconduction en le
                notifiant &agrave; la Soci&eacute;t&eacute; par e-mail au plus tard 2 semaines avant
                la date d&rsquo;expiration de l&rsquo;abonnement en cours.
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                Conform&eacute;ment aux dispositions de l&rsquo;article L215-4 du code de la
                consommation, les dispositions des articles L215-1 &agrave; L215-3 et L241-3 sont
                int&eacute;gralement reproduites ci-dessous :
            </p>
            <p class="p2">&nbsp;</p>
            <ul class="ul1">
                <li class="li2">
                    Article L215-1 du code de la consommation&nbsp;: Pour les contrats de
                    prestations de services conclus pour une dur&eacute;e d&eacute;termin&eacute;e
                    avec une clause de reconduction tacite, le professionnel prestataire de services
                    informe le consommateur par &eacute;crit, par lettre nominative ou courrier
                    &eacute;lectronique d&eacute;di&eacute;s, au plus t&ocirc;t trois mois et au
                    plus tard un mois avant le terme de la p&eacute;riode autorisant le rejet de la
                    reconduction, de la possibilit&eacute; de ne pas reconduire le contrat
                    qu&apos;il a conclu avec une clause de reconduction tacite. Cette information,
                    d&eacute;livr&eacute;e dans des termes clairs et compr&eacute;hensibles,
                    mentionne, dans un encadr&eacute; apparent, la date limite de non-reconduction.
                </li>
            </ul>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                Lorsque cette information ne lui a pas &eacute;t&eacute; adress&eacute;e
                conform&eacute;ment aux dispositions du premier alin&eacute;a, le consommateur peut
                mettre gratuitement un terme au contrat, &agrave; tout moment &agrave; compter de la
                date de reconduction.
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                Les avances effectu&eacute;es apr&egrave;s la derni&egrave;re date de reconduction
                ou, s&apos;agissant des contrats &agrave; dur&eacute;e ind&eacute;termin&eacute;e,
                apr&egrave;s la date de transformation du contrat initial &agrave; dur&eacute;e
                d&eacute;termin&eacute;e, sont dans ce cas rembours&eacute;es dans un d&eacute;lai
                de trente jours &agrave; compter de la date de r&eacute;siliation, d&eacute;duction
                faite des sommes correspondant, jusqu&apos;&agrave; celle-ci, &agrave;
                l&apos;ex&eacute;cution du contrat.
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                Les dispositions du pr&eacute;sent article s&apos;appliquent sans pr&eacute;judice
                de celles qui soumettent l&eacute;galement certains contrats &agrave; des
                r&egrave;gles particuli&egrave;res en ce qui concerne l&apos;information du
                consommateur.
            </p>
            <p class="p2">&nbsp;</p>
            <ul class="ul1">
                <li class="li2">
                    Article L215-2 du code de la consommation&nbsp;: Les dispositions du
                    pr&eacute;sent chapitre ne sont pas applicables aux exploitants des services
                    d&apos;eau potable et d&apos;assainissement.
                </li>
            </ul>
            <p class="p2">&nbsp;</p>
            <ul class="ul1">
                <li class="li2">
                    Article L215-3 du code de la consommation&nbsp;: Les dispositions du
                    pr&eacute;sent chapitre sont &eacute;galement applicables aux contrats conclus
                    entre des professionnels et des non-professionnels.
                </li>
            </ul>
            <p class="p2">&nbsp;</p>
            <ul class="ul1">
                <li class="li2">
                    Article L241-3 du code de la consommation&nbsp;: Lorsque le professionnel
                    n&apos;a pas proc&eacute;d&eacute; au remboursement dans les conditions
                    pr&eacute;vues &agrave; l&apos;article L215-1, les sommes dues sont productives
                    d&apos;int&eacute;r&ecirc;ts au taux l&eacute;gal.
                </li>
            </ul>
            <p class="p2">&nbsp;</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">7. PRIX DU SERVICE</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                L&rsquo;abonnement implique le paiement par l&rsquo;Abonn&eacute; d&rsquo;un prix
                mensuel forfaitaire de 4,99&euro; (quatre euros et quatre-vingt-dix-neuf centimes).
            </p>
            <p class="p2">
                Toute modification du prix mensuel tarifaire sera port&eacute;e &agrave; la
                connaissance de l&rsquo;Abonn&eacute; au moins un (1) mois avant son entr&eacute;e
                en vigueur. Dans ce cas, l&rsquo;Abonn&eacute; aura la facult&eacute; de
                r&eacute;silier son Abonnement, dans les conditions pr&eacute;vues &agrave;
                l&rsquo;article 12.
            </p>
            <p class="p2">
                La Soci&eacute;t&eacute; n&rsquo;accordera aucun remboursement au prorata temporis
                pour une utilisation partielle du Service.
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">8. MODALIT&Eacute;S DE PAIEMENT DU SERVICE</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                Le paiement est effectu&eacute; par carte bancaire &agrave; travers le service de
                paiement s&eacute;curis&eacute; Stripe (https://stripe.com/fr)
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                Le pr&eacute;l&egrave;vement du prix de l&apos;abonnement mensuel s&apos;effectue
                &agrave; chaque &eacute;ch&eacute;ance, &agrave; la date anniversaire de
                l&rsquo;abonnement (correspondant &agrave; la date d&rsquo;inscription au Service)
                et &agrave; partir des informations de paiement que l&rsquo;Abonn&eacute; aura
                renseign&eacute; lors de son Abonnement et qu&rsquo;il pourra retrouver sur son
                compte client, sous la rubrique &laquo; Mon compte &raquo;.
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                De ce fait, l&rsquo;Abonn&eacute; est tenu de mettre &agrave; jour et / ou modifier
                ses coordonn&eacute;es bancaires via son compte, rubrique &laquo; G&eacute;rer mon
                abonnement &raquo;, notamment dans le cas o&ugrave; la validit&eacute; des
                coordonn&eacute;es arrive &agrave; &eacute;ch&eacute;ance.
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                Les factures seront envoy&eacute;es &agrave; chaque pr&eacute;l&egrave;vement par
                email.
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">9. OFFRES &Eacute;PH&Eacute;M&Egrave;RES</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                La Soci&eacute;t&eacute; pourra proposer aux Utilisateurs des offres
                &eacute;ph&eacute;m&egrave;res d&apos;abonnement, pour lesquelles elle se
                r&eacute;serve le droit discr&eacute;tionnaire de choisir les prix, les
                dur&eacute;es d&apos;abonnement, les p&eacute;riodes d&apos;essai gratuites ainsi
                que les dur&eacute;es pendant lesquelles elles seront disponibles &agrave;
                l&apos;abonnement.
            </p>
            <p class="p2">
                L&apos;Abonn&eacute; pourra trouver les Mentions l&eacute;gales des offres en cours
                sur le Service dans la rubrique &laquo; Modalit&eacute; des offres &raquo;
                pr&eacute;sente sur le Site Internet.
            </p>
            <p class="p2">
                Le processus d&apos;abonnement &agrave; ces offres &eacute;ph&eacute;m&egrave;res
                sera celui indiqu&eacute; &agrave; l&apos;article 5.b.
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">10. R&Eacute;TRACTATION</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                Conform&eacute;ment &agrave; l&rsquo;article L. 221-28 13&deg; du Code de la
                consommation, l&rsquo;Abonn&eacute; ne peut exercer son droit de r&eacute;tractation
                dans le cadre de la fourniture d&rsquo;un contenu num&eacute;rique non fourni sur un
                support mat&eacute;riel dont l&apos;ex&eacute;cution a commenc&eacute; apr&egrave;s
                accord pr&eacute;alable expr&egrave;s du Client et renoncement expr&egrave;s
                &agrave; son droit de r&eacute;tractation.
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                Afin de b&eacute;n&eacute;ficier de l&rsquo;acc&egrave;s illimit&eacute; au contenu
                du Service, l&rsquo;Abonn&eacute; devra renoncer express&eacute;ment &agrave; son
                droit de r&eacute;tractation lors de la validation de son abonnement.
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">11. R&Eacute;SILIATION</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">a. RESILIATION PAR LA SOCI&Eacute;T&Eacute;</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                La Soci&eacute;t&eacute; dispose de la facult&eacute; de r&eacute;silier et / ou
                suspendre l&rsquo;abonnement de plein droit sans d&eacute;lai en cas :
            </p>
            <p class="p2">&nbsp;</p>
            <ul class="ul1">
                <li class="li2">
                    De non-paiement de l&rsquo;abonnement par l&rsquo;Abonn&eacute;.
                </li>
            </ul>
            <p class="p2">&nbsp;</p>
            <ul class="ul1">
                <li class="li2">
                    D&rsquo;absence de mise &agrave; jour des informations relatives &agrave; la
                    carte bancaire &agrave; l&rsquo;&eacute;ch&eacute;ance de sa dur&eacute;e de
                    validit&eacute;.
                </li>
            </ul>
            <p class="p2">&nbsp;</p>
            <ul class="ul1">
                <li class="li2">
                    D&rsquo;agissements contrevenant aux dispositions du Code de la
                    propri&eacute;t&eacute; intellectuelle.
                </li>
            </ul>
            <p class="p2">&nbsp;</p>
            <ul class="ul1">
                <li class="li2">
                    Et plus g&eacute;n&eacute;ralement, en cas de manquements de
                    l&rsquo;Abonn&eacute; &agrave; l&rsquo;une quelconque des obligations qui lui
                    incombent en vertu des pr&eacute;sentes CGU.
                </li>
            </ul>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                Cette r&eacute;siliation entra&icirc;nera la d&eacute;sactivation imm&eacute;diate
                de l&rsquo;Abonnement au Service, sans droit &agrave; indemnit&eacute; ni &agrave;
                remboursement.
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">b. R&Eacute;SILIATION PAR L&rsquo;ABONN&Eacute;</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                La r&eacute;siliation des abonnements se fait, sans frais, depuis l&rsquo;espace
                &laquo; Mon Compte &raquo; accessible sur le Site Internet.
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                La r&eacute;siliation prend effet imm&eacute;diatement. Il est rappel&eacute; que
                tout abonnement commenc&eacute; est d&ucirc; dans son int&eacute;gralit&eacute;. En
                cas de d&eacute;sinscription en cours d&rsquo;abonnement l&rsquo;Abonn&eacute;
                n&rsquo;a pas droit &agrave; un remboursement au prorata.
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">12. DONN&Eacute;ES &Agrave; CARACT&Egrave;RE PERSONNEL</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                Conform&eacute;ment &agrave; la r&eacute;glementation relative &agrave; la
                protection des donn&eacute;es personnelles, la Soci&eacute;t&eacute; est
                amen&eacute;e &agrave; traiter des donn&eacute;es personnelles des Utilisateurs en
                sa qualit&eacute; de responsable du traitement. La politique de traitement des
                donn&eacute;es se trouve &agrave; l&rsquo;adresse suivante :
                https://www.chevaltv.aitakes.com/confidentialite.
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">13. OBLIGATIONS ET RESPONSABILIT&Eacute; DE L&apos;ABONN&Eacute;</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">OBLIGATIONS G&Eacute;N&Eacute;RALES</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                Les informations fournies par l&rsquo;Abonn&eacute; doivent &ecirc;tre loyales,
                exactes et compl&egrave;tes. La soci&eacute;t&eacute; se r&eacute;serve le droit de
                ne pas autoriser ou maintenir l&rsquo;acc&egrave;s au Service en cas de non-respect
                de ces exigences.
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">RESPONSABILIT&Eacute; DE L&apos;ABONN&Eacute;</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                En utilisant le Service, l&rsquo;Abonn&eacute; s&rsquo;engage &agrave; en faire une
                utilisation responsable en conformit&eacute; avec l&rsquo;ensemble des dispositions
                pr&eacute;vues aux pr&eacute;sentes CGU et dans le respect des lois en vigueur.
            </p>
            <p class="p2">
                L&rsquo;Abonn&eacute; s&rsquo;engage notamment &agrave; utiliser le Service selon
                des modalit&eacute;s autoris&eacute;es par les pr&eacute;sentes CGU ; &agrave; ne
                pas utiliser le Service &agrave; des fins ill&eacute;gales ou illicites ; &agrave;
                ne pas faire un usage commercial des informations, services et contenus fournis sur
                le Service ; &agrave; ne pas propager des logiciels malveillants, des chevaux de
                Troie ou des virus, &agrave; ne pas usurper l&rsquo;identit&eacute; d&rsquo;un
                Abonn&eacute; ou utiliser un compte qui ne lui appartient pas, et d&rsquo;une
                mani&egrave;re g&eacute;n&eacute;rale ne pas porter atteinte &agrave; la
                Soci&eacute;t&eacute; ou un autre Utilisateur d&rsquo;une quelconque fa&ccedil;on.
            </p>
            <p class="p2">
                L&rsquo;Abonn&eacute; est notamment tenu de respecter les dispositions de la loi
                n&deg;78-17 relative &agrave; l&rsquo;informatique, aux fichiers et aux
                libert&eacute;s du 6 janvier 1978 modifi&eacute;e, dont la violation est passible de
                sanctions p&eacute;nales. Entre autres, il doit s&rsquo;abstenir de toute collecte,
                de toute utilisation d&eacute;tourn&eacute;e et, d&rsquo;une mani&egrave;re
                g&eacute;n&eacute;rale, de tout acte susceptible de porter atteinte &agrave; la vie
                priv&eacute;e ou &agrave; la r&eacute;putation des personnes.
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">14. OBLIGATION ET RESPONSABILIT&Eacute; DE LA SOCI&Eacute;T&Eacute;</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                La Soci&eacute;t&eacute; met tout en &oelig;uvre pour offrir aux Abonn&eacute;s des
                informations ou des outils disponibles et v&eacute;rifi&eacute;s, mais ne saurait
                &ecirc;tre tenue responsable, n&rsquo;&eacute;tant tenue qu&rsquo;&agrave; une
                obligation de moyens :
            </p>
            <p class="p2">&nbsp;</p>
            <ul class="ul1">
                <li class="li2">
                    De la qualit&eacute; du Service, le Service &eacute;tant propos&eacute; &laquo;
                    en l&rsquo;&eacute;tat &raquo;.
                </li>
            </ul>
            <p class="p2">&nbsp;</p>
            <ul class="ul1">
                <li class="li2">De la perturbation de l&rsquo;utilisation du Service.</li>
            </ul>
            <p class="p2">&nbsp;</p>
            <ul class="ul1">
                <li class="li2">De l&rsquo;impossibilit&eacute; d&rsquo;utiliser le Service.</li>
            </ul>
            <p class="p2">&nbsp;</p>
            <ul class="ul1">
                <li class="li2">
                    Des atteintes &agrave; la s&eacute;curit&eacute; informatique non imputables
                    &agrave; la Soci&eacute;t&eacute; pouvant causer des dommages aux
                    mat&eacute;riels informatiques des Abonn&eacute;s et &agrave; leurs
                    donn&eacute;es.
                </li>
            </ul>
            <p class="p2">&nbsp;</p>
            <ul class="ul1">
                <li class="li2">
                    De l&rsquo;atteinte aux droits des Abonn&eacute;s de mani&egrave;re
                    g&eacute;n&eacute;rale.
                </li>
            </ul>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                La qualit&eacute; d&apos;affichage du contenu peut varier d&apos;un appareil
                &agrave; l&apos;autre et peut &ecirc;tre influenc&eacute;e par diff&eacute;rents
                facteurs, dont la localisation, la bande passante disponible et / ou la vitesse de
                la connexion Internet. De m&ecirc;me, tout le contenu n&apos;est pas disponible dans
                tous les formats, notamment les formats HD, Ultra HD et HDR.
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                Les frais de connexion &agrave; Internet sont &agrave; la charge de
                l&rsquo;Utilisateur. Ce dernier est invit&eacute; &agrave; se rapprocher de son
                fournisseur d&rsquo;acc&egrave;s Internet pour conna&icirc;tre le d&eacute;tail de
                ces frais.
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                La Soci&eacute;t&eacute; s&rsquo;engage &agrave; supprimer les contenus illicites,
                une fois que ceux-ci lui auront &eacute;t&eacute; signal&eacute;s au moyen des
                boutons de signalement pr&eacute;sents sur le Site ou d&rsquo;une notification
                comportant les &eacute;l&eacute;ments &eacute;dict&eacute;s &agrave; l&rsquo;article
                6-I-5 de la loi n&deg; 2004-575 du 21 juin 2004 pour la confiance dans
                l&rsquo;&eacute;conomie num&eacute;rique. De mani&egrave;re plus
                g&eacute;n&eacute;rale, la Soci&eacute;t&eacute; s&rsquo;engage &agrave; supprimer
                tout contenu qui ne respecterait pas les pr&eacute;sentes CGU.
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                La Soci&eacute;t&eacute; se r&eacute;serve le droit de r&eacute;viser, supprimer,
                valider, changer ou modifier le Service, int&eacute;gralement ou en partie, en tout
                temps et sans pr&eacute;avis.
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">15. PROPRI&Eacute;T&Eacute; INTELLECTUELLE</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                Le contenu du site, la structure g&eacute;n&eacute;rale ainsi que les logiciels,
                textes, images anim&eacute;es ou non, photographies, son savoir-faire et tous les
                autres &eacute;l&eacute;ments composant le site sont la propri&eacute;t&eacute;
                exclusive de Bo Travail ! ou des tiers qui lui ont conc&eacute;d&eacute; les droits
                n&eacute;cessaires.
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                Les pr&eacute;sentes CGU n&rsquo;emportent aucune cession d&rsquo;aucune sorte de
                droit de propri&eacute;t&eacute; intellectuelle sur les &eacute;l&eacute;ments
                appartenant &agrave; la soci&eacute;t&eacute; ou ayant droit tels que les sons,
                vid&eacute;os, photographies, images, textes litt&eacute;raires, travaux
                artistiques, logiciels, marques, chartes graphiques, logos au b&eacute;n&eacute;fice
                de l&rsquo;Abonn&eacute;.
            </p>
            <p class="p2">
                L&rsquo;Abonn&eacute; s&apos;interdit notamment de modifier, copier, reproduire,
                t&eacute;l&eacute;charger sauf dans le cadre du visionnage hors ligne conforme aux
                pr&eacute;sentes CGU, diffuser, transmettre, cr&eacute;er des &oelig;uvres
                d&eacute;riv&eacute;es, exploiter commercialement et/ou distribuer de quelque
                fa&ccedil;on que ce soit les Services, les contenus audiovisuels, les pages du site,
                ou les codes informatiques des &eacute;l&eacute;ments composant le Service.
            </p>
            <p class="p2">
                Toute reproduction et/ou repr&eacute;sentation, totale ou partielle d&rsquo;un de
                ces droits, sans l&rsquo;autorisation expresse de la Soci&eacute;t&eacute;, est
                interdite et constituerait une contrefa&ccedil;on susceptible d&apos;engager la
                responsabilit&eacute; civile et p&eacute;nale du contrefacteur.
            </p>
            <p class="p2">
                Le Service, ainsi que tout contenu regard&eacute; via le service, est
                r&eacute;serv&eacute; &agrave; un usage uniquement personnel et non commercial et ne
                doit pas &ecirc;tre partag&eacute; avec des personnes ext&eacute;rieures au foyer de
                l&rsquo;Utilisateur. Pendant la dur&eacute;e de l&rsquo;abonnement, la
                Soci&eacute;t&eacute; accorde &agrave; l&rsquo;Utilisateur un droit limit&eacute;,
                non exclusif et non transf&eacute;rable lui permettant d&apos;acc&eacute;der au
                Service et de regarder le contenu du Service. &Agrave; l&apos;exception de ce qui
                pr&eacute;c&egrave;de, aucun droit, titre ou int&eacute;r&ecirc;t ne lui est
                accord&eacute;. Il ne peut pas utiliser le Service pour des projections publiques.
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                En cons&eacute;quence, l&rsquo;Abonn&eacute; s&apos;interdit tout agissement et tout
                acte susceptible de porter atteinte directement ou non aux droits de
                propri&eacute;t&eacute; intellectuelle de la soci&eacute;t&eacute;.
            </p>
            <p class="p2">
                Il en est de m&ecirc;me des bases de donn&eacute;es figurant, le cas
                &eacute;ch&eacute;ant sur le Site internet qui sont prot&eacute;g&eacute;es par les
                articles du Code de la propri&eacute;t&eacute; intellectuelle.
            </p>
            <p class="p2">
                Les signes distinctifs de la soci&eacute;t&eacute; et de ses partenaires, tels que
                les noms de domaine, marques, d&eacute;nominations ainsi que les logos figurant sur
                le Service sont prot&eacute;g&eacute;s par le Code de la propri&eacute;t&eacute;
                intellectuelle. Toute reproduction totale ou partielle de ces signes distinctifs
                effectu&eacute;e &agrave; partir des &eacute;l&eacute;ments du site sans
                autorisation expresse de Cheval TV
                <span class="Apple-converted-space">&nbsp;&nbsp;</span>est donc prohib&eacute;e, au
                sens du Code de la propri&eacute;t&eacute; intellectuelle.
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                Seule une utilisation conforme &agrave; la destination de ce site est
                autoris&eacute;e.
            </p>
            <p class="p2">
                Toutes les autres utilisations, non express&eacute;ment autoris&eacute;e par
                &eacute;crit et au pr&eacute;alable par CHEVAL TV est prohib&eacute;e et
                constitutive de contrefa&ccedil;on.
            </p>
            <p class="p2">
                L&rsquo;Abonn&eacute; garantit la Soci&eacute;t&eacute; contre toute action,
                r&eacute;clamation, revendication ou opposition de toute personne invoquant un droit
                de propri&eacute;t&eacute; intellectuelle ou un acte de concurrence d&eacute;loyale
                et/ou parasitaire auquel un commentaire de l&rsquo;Abonn&eacute; aurait port&eacute;
                atteinte et prendra &agrave; sa charge les frais engag&eacute;s par la
                Soci&eacute;t&eacute; pour engager sa d&eacute;fense et les &eacute;ventuelles
                condamnations.
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">&nbsp;</p>
            <p class="p4">
                <br />
            </p>
            <p class="p4">
                <br />
            </p>
            <p class="p4">
                <br />
            </p>
            <p class="p4">
                <br />
            </p>
            <p class="p4">
                <br />
            </p>
            <p class="p4">
                <br />
            </p>
            <p class="p2">16. FORCE MAJEURE</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                Dans un premier temps, les cas de force majeure suspendront l&rsquo;ex&eacute;cution
                des pr&eacute;sentes.
            </p>
            <p class="p2">
                Si les cas de force majeure ont une dur&eacute;e d&rsquo;existence sup&eacute;rieure
                &agrave; deux mois, les pr&eacute;sentes seront r&eacute;sili&eacute;es
                automatiquement, sauf accord contraire des parties.
            </p>
            <p class="p2">
                De fa&ccedil;on expresse, sont consid&eacute;r&eacute;s comme cas de force majeure
                ou cas fortuits, ceux habituellement retenus par la jurisprudence des cours et
                tribunaux fran&ccedil;ais, ainsi que les &eacute;v&eacute;nements suivants :
            </p>
            <p class="p2">
                La guerre, l&rsquo;&eacute;meute, l&rsquo;incendie, les gr&egrave;ves internes ou
                externes, lock-out, occupation des locaux de la soci&eacute;t&eacute;,
                intemp&eacute;ries, tremblement de terre, inondation, d&eacute;g&acirc;t des eaux,
                restrictions l&eacute;gales ou gouvernementales, modifications l&eacute;gales ou
                r&eacute;glementaires des formes de commercialisation, les accidents de toutes
                natures, &eacute;pid&eacute;mie, pand&eacute;mie, maladie touchant plus de 10% du
                personnel de la soci&eacute;t&eacute; dans une p&eacute;riode de deux mois
                cons&eacute;cutifs, l&rsquo;absence de fourniture d&rsquo;&eacute;nergie,
                l&rsquo;arr&ecirc;t partiel ou total du r&eacute;seau Internet et, de mani&egrave;re
                plus g&eacute;n&eacute;rale, des r&eacute;seaux de t&eacute;l&eacute;communications
                priv&eacute;s ou publics, les blocages de routes et les impossibilit&eacute;s
                d&rsquo;approvisionnement en fournitures et tout autre cas ind&eacute;pendant de la
                volont&eacute; expresse des parties emp&ecirc;chant l&rsquo;ex&eacute;cution normale
                de la pr&eacute;sente convention.
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">17. CONCILIATION</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                Les litiges qui pourraient &eacute;ventuellement survenir entre
                l&rsquo;Abonn&eacute; et la Soci&eacute;t&eacute; feront l&rsquo;objet d&rsquo;une
                tentative de r&eacute;solution amiable avant toute action.
            </p>
            <p class="p2">
                Cette clause est juridiquement autonome du pr&eacute;sent contrat. Elle continue
                &agrave; s&rsquo;appliquer malgr&eacute; l&rsquo;&eacute;ventuelle nullit&eacute;,
                r&eacute;solution, r&eacute;siliation ou d&rsquo;an&eacute;antissement des
                pr&eacute;sentes relations contractuelles.
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">18. LOI APPLICABLE</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                Les pr&eacute;sentes CGU du Service sont r&eacute;gies par la loi fran&ccedil;aise.
            </p>
            <p class="p2">
                Il en est ainsi pour les r&egrave;gles de fond et les r&egrave;gles de forme, et ce,
                nonobstant les lieux d&rsquo;ex&eacute;cution des obligations substantielles ou
                accessoires.
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">19. DISPOSITION DIVERSES</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">19.1. Sous-traitants</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                La Soci&eacute;t&eacute; pourra faire appel &agrave; des sous-traitants dans le
                cadre de l&rsquo;ex&eacute;cution de ses obligations au titre des pr&eacute;sentes
                CGU.
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">19.2. Cession</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                La Soci&eacute;t&eacute; peut c&eacute;der le pr&eacute;sent Contrat ainsi que
                l&rsquo;ensemble des droits et obligations qui y sont attach&eacute;s, &agrave; tous
                tiers, sans accord pr&eacute;alable et &eacute;crit de l&rsquo;Utilisateur,
                notamment en cas de cession de branche d&rsquo;activit&eacute;, de fusion par
                constitution d&rsquo;une nouvelle soci&eacute;t&eacute;, de fusion-absorption, de
                scission ou de tout changement de contr&ocirc;le affectant la Soci&eacute;t&eacute;.
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">19.3. Documents contractuels</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                Les pr&eacute;sentes CGU constituent l&apos;int&eacute;gralit&eacute; de
                l&apos;accord pass&eacute; entre la Soci&eacute;t&eacute; et l&rsquo;Utilisateur
                pour ce qui concerne l&apos;utilisation du Site et du Service, et se substituent
                &agrave; tout accord ayant le m&ecirc;me objet &eacute;ventuellement intervenu
                ant&eacute;rieurement entre l&rsquo;Utilisateur et la Soci&eacute;t&eacute;.
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                L&rsquo;Utilisateur reconna&icirc;t avoir eu compr&eacute;hension,
                pr&eacute;alablement &agrave; la conclusion du contrat, d&rsquo;une mani&egrave;re
                claire et compr&eacute;hensible, des pr&eacute;sentes CGU et de tous les
                renseignements et informations vis&eacute;s &agrave; l&rsquo;article L121-19 du code
                de la consommation.
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">19.4. Non-renonciation</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                Le fait pour la Soci&eacute;t&eacute; de ne pas se pr&eacute;valoir &agrave; un
                moment donn&eacute; d&rsquo;une stipulation quelconque ne peut &ecirc;tre
                consid&eacute;r&eacute; comme valant renonciation au b&eacute;n&eacute;fice de cette
                stipulation ou au droit de se pr&eacute;valoir ult&eacute;rieurement de ladite
                stipulation et / ou de demander r&eacute;paration pour tout manquement &agrave;
                cette stipulation.
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">19.5. Divisibilit&eacute;</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                En cas de nullit&eacute; d&rsquo;une quelconque disposition des pr&eacute;sentes
                CGU, celle-ci est r&eacute;put&eacute;e non-&eacute;crite et les autres dispositions
                conservent leur force et leur port&eacute;e.
            </p>
            <p class="p2">&nbsp;</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">19.6. Preuve</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                Les parties reconnaissent et conviennent que les SMS, les MMS, les courriers
                &eacute;lectroniques &eacute;chang&eacute;s entre la Soci&eacute;t&eacute; et
                l&rsquo;Utilisateur dans le cadre de la fourniture du Service et tous autres
                &eacute;crits &eacute;lectroniques, issus de l&rsquo;utilisation des Services
                constituent :
            </p>
            <p class="p2">&nbsp;</p>
            <ul class="ul1">
                <li class="li2">Des documents originaux entre les parties.</li>
            </ul>
            <p class="p2">&nbsp;</p>
            <ul class="ul1">
                <li class="li2">Feront preuve.</li>
            </ul>
            <p class="p2">&nbsp;</p>
            <ul class="ul1">
                <li class="li2">
                    Seront admissibles devant les tribunaux, sous r&eacute;serve que puisse
                    &ecirc;tre d&ucirc;ment identifi&eacute;e la personne dont ils &eacute;manent et
                    qu&rsquo;ils soient &eacute;tablis et conserv&eacute;s dans des conditions
                    permettant d&rsquo;en garantir l&rsquo;int&eacute;grit&eacute;.
                </li>
            </ul>
            <p class="p2">&nbsp;</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">19.7. Contacter la Soci&eacute;t&eacute;</p>
            <p class="p2">&nbsp;</p>
            <p class="p2">
                De mani&egrave;re g&eacute;n&eacute;rale, si l&rsquo;Utilisateur a des questions ou
                des remarques concernant les pr&eacute;sentes CGU ou l&rsquo;utilisation du Site, il
                peut contacter la Soci&eacute;t&eacute; par email &agrave; l&rsquo;adresse suivante:
                abonnement@chevaltv.fr
            </p>
        </>
    );
};

export default CGU;
