import React, { useEffect, useState, useMemo } from 'react';
import {
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
    useStripe,
    useElements
} from '@stripe/react-stripe-js';
import useAuth from 'hooks/useAuth';
import Input from 'components/Input';
import ApiService from 'services/ApiService';

const style = {
    base: { color: '#404040' },
    invalid: { color: ' #E33939' }
};

const StripeCardForm = () => {
    const [cardNumber, setCardNumber] = useState({
        empty: true,
        complete: false,
        error: false
    });
    const [cardExpiry, setCardExpiry] = useState({
        empty: true,
        complete: false,
        error: false
    });
    const [cardCvc, setCardCvc] = useState({
        empty: true,
        complete: false,
        error: false
    });
    const [cardName, setCardName] = useState('');
    const [error, setError] = useState(false);
    const [showPromoCode, setShowPromoCode] = useState(false);
    const [promoCode, setPromoCode] = useState('');
    const [processing, setProcessing] = useState(false); //TODO loader
    const [offers, setOffers] = useState([]);
    const [codeError, setCodeError] = useState('');
    const [appliedCode, setAppliedCode] = useState({});

    const stripe = useStripe();

    const elements = useElements();
    const { user, getUser } = useAuth();

    const buttonDisabled = useMemo(
        () =>
            !stripe ||
            processing ||
            cardNumber.empty ||
            cardExpiry.empty ||
            cardCvc.empty ||
            cardNumber.error ||
            cardExpiry.error ||
            cardCvc.error ||
            cardName.length < 2,
        [cardCvc, cardExpiry, cardName, cardNumber, processing, stripe]
    );

    const priceToPay = useMemo(() => {
        const { type, amount } = appliedCode;
        switch (type) {
            case '%':
                return Math.floor((offers[0]?.price * (100 - amount)) / 100);
            case '€':
                return offers[0]?.price - amount;
            default:
                return offers[0]?.price;
        }
    }, [offers, appliedCode]);

    const onClickButtonHandler = async event => {
        event.preventDefault();

        if (cardName.length < 2) {
            setError('Veuillez entrer le nom associé à la carte de crédit');
            return;
        }

        if (error?.length > 0) setError(null);
        setProcessing(true);

        const cardNumberElement = elements.getElement(CardNumberElement);
        const { error: stripeError, token: stripeToken } = await stripe.createToken(
            cardNumberElement
        );

        if (stripeError) {
            setError(stripeError.message);
            return;
        }

        const data = new URLSearchParams();
        data.append('abo_id', offers[0].stripe_id); // TODO: offer selected
        data.append('coupon', promoCode);
        data.append('stripeToken', stripeToken.id);
        data.append('user_id', user.NoCli);
        data.append('email', user.email);

        ApiService.post('site/payment/create_intent_usual.php', data)
            .then(res => {
                if ( res.data.error ) throw new Error();
                if ( res.data.result.latest_invoice && res.data.result.latest_invoice.payment_intent && res.data.result.latest_invoice.payment_intent.client_secret ) {
                    confirmPayment( cardNumberElement, res.data.result.latest_invoice.payment_intent.client_secret );
                } else {
                    setError('Une erreur est survenue!');
                    setProcessing(false);
                }
            })
            .catch(err => {
                setError('Une erreur est survenue!');
                setProcessing(false);
            });
    };

    const confirmPayment = ( cardNumberElement, clientSecret ) => {
        stripe.confirmCardPayment( clientSecret, { payment_method: { card: cardNumberElement } } )
            .then( res2 => {
                if ( res2.error ) {
                    setError('Une erreur est survenue!');
                    setProcessing(false);
                } else {
                    const data = new URLSearchParams();
                    data.append('amount', priceToPay);
                    data.append('from', 'stripe');
                    data.append('typeAbo', '3');
                    data.append('user_id', user.NoCli);
                    data.append('email', user.email);
                    data.append('promoCode', promoCode);
                    
                    ApiService.post('site/payment/charge_usual.php', data)
                        .then(res => {
                            if (!res.data.ret) throw new Error();
                            getUser(user.NoCli);
                        })
                        .catch(err => {
                            // console.log(' err ', err); // TODO del
                        })
                        .finally(() => setProcessing(false));
                }
            });
    };

    const handleChange = async event => {
        const { elementType, empty, complete, error } = event;

        switch (elementType) {
            case 'cardNumber':
                setCardNumber({ empty, complete, error });
                break;
            case 'cardExpiry':
                setCardExpiry({ empty, complete, error });
                break;
            case 'cardCvc':
                setCardCvc({ empty, complete, error });
                break;
            default:
                break;
        }

        // setError(event.error ? event.error.message : '');
    };

    useEffect(() => {
        setProcessing(true);
        ApiService.get('site/offers/get_offers.php')
            .then(res => {
                setOffers(res.data.prices);
            })
            .finally(() => setProcessing(false));
    }, []);

    const onClickValidateCodeHandler = event => {
        if (appliedCode.id) {
            setPromoCode('');
            setAppliedCode({});
            return;
        }

        const code = promoCode.trim();

        const data = new URLSearchParams();
        data.append('code', code);
        data.append('sub_id', offers[0].id);

        ApiService.post('site/offers/check_code.php', data)
            .then(res => {
                if (res.data.code_info) {
                    setAppliedCode(res.data.code_info);
                }

                if (res.data.error) {
                    setCodeError('Code invalide');
                    setPromoCode('');
                    return;
                }
            })
            .catch(err => {
                console.log('err', err);
            });
    };

    return (
        <>
            <div className="subscription__list">
                <div className="subscription__list__title">Abonnement</div>
                {offers
                    .filter(offer => offer.is_active === 1)
                    .map((offer, i) => (
                        <div key={i} className="subscription__item">
                            <div className="subscription__item__selector">
                                <span className="selector"></span>
                            </div>
                            <div className="subscription__item__data">
                                <div className="subscription__item__duration">
                                    {offer.title || `${offer.month_count} mois`}
                                </div>
                                <div className={'subscription__item__price'}>
                                    <span className="price">
                                        {priceToPay &&
                                            priceToPay !== offers[0]?.price &&
                                            `${priceToPay / 100}€ le premier mois puis `}
                                        {`${offers[0]?.price / 100}€ / mois`}
                                    </span>
                                </div>
                                {/* <div className={'subscription__item__price'}>
                                    <span className="price">
                                        {priceToPay && priceToPay !== offers[0]?.price && (
                                            <span className="price--crossed">{`${
                                                offers[0]?.price / 100
                                            }€ `}</span>
                                        )}
                                        {`${priceToPay / 100}€ / mois`}
                                    </span>
                                </div> */}
                                <div className="subscription__item__billing">
                                    {offer.text2 || 'Tous les mois'}
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
            {showPromoCode && (
                <Input
                    label="Code promo"
                    name="card-name"
                    onChange={e => setPromoCode(e.target.value.toUpperCase())}
                    type="text"
                    value={promoCode}
                    inlineValidation
                    onClickValidate={onClickValidateCodeHandler}
                    withValidation
                    validateButtonText={appliedCode.id ? 'Retirer' : 'Appliquer'}
                    disabled={!!appliedCode.id}
                />
            )}
            <div className="form__input form__input--inline form__input--separator">
                <input
                    type="checkbox"
                    id="got-code"
                    value={showPromoCode}
                    onChange={e => setShowPromoCode(e.target.checked)}
                />
                <label htmlFor="got-code">J'ai un code promo</label>
            </div>
            <Input
                label="Nom sur la carte*"
                name="card-name"
                onChange={e => setCardName(e.target.value)}
                type="text"
                value={cardName}
            />
            <div className="form__input form__input--stripe">
                <label>Informations de la carte*</label>
                <CardNumberElement
                    className="input --first"
                    onChange={handleChange}
                    options={{ style }}
                />
                <div className="form__input--inline">
                    <CardExpiryElement
                        className="input input--half"
                        options={{ style }}
                        onChange={handleChange}
                    />
                    <CardCvcElement
                        className="input input--half"
                        options={{ style }}
                        onChange={handleChange}
                    />
                </div>
                {cardNumber?.error?.message?.length > 0 && (
                    <div className="form__input__error">{cardNumber.error.message}</div>
                )}
                {cardExpiry?.error?.message?.length > 0 && (
                    <div className="form__input__error">{cardExpiry.error.message}</div>
                )}
                {cardCvc?.error?.message?.length > 0 && (
                    <div className="form__input__error">{cardCvc.error.message}</div>
                )}
                <button className="button" onClick={onClickButtonHandler} disabled={buttonDisabled}>
                    S'abonner
                </button>
            </div>
        </>
    );
};

export default StripeCardForm;
