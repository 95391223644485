import React, { useEffect } from 'react';

const PrivacyStyle = () => (
    <style type="text/css">
        {`
        p.p1 {
            margin: 0.0px 0.0px 0.0px 0.0px;
            text-align: center;
            font: 16.0px
        }
    
        p.p2 {
            margin: 0.0px 0.0px 0.0px 0.0px;
            font: 12.0px;
            min-height: 14.0px
        }
    
        p.p3 {
            margin: 0.0px 0.0px 30.0px 0.0px;
            text-align: center;
            font: 24.0px;
            color: #1d1d1b
        }
    
        p.p4 {
            margin: 0.0px 0.0px 0.0px 0.0px;
            font: 13.0px;
            color: #1d1d1b
        }
    
        p.p5 {
            margin: 0.0px 0.0px 0.0px 0.0px;
            font: 24.0px;
            color: #1d1d1b
        }
    
        p.p6 {
            margin: 5.0px 0.0px 5.0px 0.0px;
            font: 13.0px;
            color: #1d1d1b
        }
    
        p.p7 {
            margin: 0.0px 0.0px 0.0px 0.0px;
            font: 13.0px;
            color: #1d1d1b;
            min-height: 15.0px
        }
    
        li.li4 {
            margin: 0.0px 0.0px 0.0px 0.0px;
            font: 13.0px;
            color: #1d1d1b
        }
    
        span.s1 {
            color: #000000
        }
    
        ul.ul1 {
            list-style-type: disc
        }
    `}
    </style>
);

const Privacy = ({ setTitle, setSubTitle }) => {
    useEffect(() => {
        setTitle('');
        setSubTitle('');
    }, [setTitle, setSubTitle]);

    return (
        <>
            <PrivacyStyle />
            <p className="p1">
                <strong>DECLARATION DE CONFIDENTIALITE</strong>
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p2">
                <br />
            </p>
            <p className="p3">
                <strong>Mentions L&eacute;gales</strong>
            </p>
            <p className="p4">&nbsp;</p>
            <p className="p5">Confidentialit&eacute;</p>
            <p className="p4">&nbsp;</p>
            <p className="p6">
                Cette D&eacute;claration de confidentialit&eacute; explique nos pratiques, ainsi que
                vos choix, concernant la collecte, l&apos;utilisation et la divulgation de certaines
                donn&eacute;es, y compris vos donn&eacute;es personnelles, par CHEVAL TV,
                &eacute;dit&eacute; et propos&eacute; par la soci&eacute;t&eacute; CHEVAL TV
                Soci&eacute;t&eacute; par Actions Simplifi&eacute;e au capital de 10 000 euros,
                immatricul&eacute;e au Registre du Commerce et des Soci&eacute;t&eacute;s de Melun
                sous le num&eacute;ro{' '}
                <span className="s1">
                    dont le si&egrave;ge social se trouve au 52 rue de la for&ecirc;t
                    <span className="Apple-converted-space">&nbsp;&nbsp;</span>77630
                    Saint-Martin-en-Bri&egrave;re, et repr&eacute;sent&eacute;e par son
                    Pr&eacute;sident Monsieur Jean Morel.
                </span>
            </p>
            <p className="p4">
                CHEVAL<span className="Apple-converted-space">&nbsp;&nbsp;</span>TV est un service
                de vid&eacute;o &agrave; la demande par abonnement fournissant &agrave; ses
                utilisateurs l&apos;acc&egrave;s &agrave; des films diffus&eacute;s en streaming par
                Internet sur les ordinateurs, t&eacute;l&eacute;viseurs connect&eacute;s, tablettes
                et smartphones.
            </p>
            <p className="p4">&nbsp;</p>
            <p className="p4">&nbsp;</p>
            <p className="p4">Nous contacter</p>
            <p className="p4">&nbsp;</p>
            <p className="p4">
                Pour toute question concernant votre compte ou la fa&ccedil;on de contacter le
                service client&egrave;le pour obtenir de l&apos;aide, veuillez envoyer un message
                &agrave; abonnement@chevaltv.fr Si vous avez des questions concernant cette
                D&eacute;claration de confidentialit&eacute; ou sur notre utilisation de vos
                donn&eacute;es personnelles, des cookies et d&apos;autres technologies similaires,
                veuillez contacter notre D&eacute;l&eacute;gu&eacute; &agrave; la protection des
                donn&eacute;es/Bureau de la protection des donn&eacute;es par e-mail &agrave;
                l&apos;adresse suivante : abonnement@chevaltv.fr
            </p>
            <p className="p4">&nbsp;</p>
            <p className="p4">
                Le responsable du traitement de vos donn&eacute;es personnelles est CHEVAL TV.
                Veuillez noter que si vous nous contactez pour obtenir de l&apos;aide, nous devrons
                peut-&ecirc;tre vous identifier avant de r&eacute;pondre &agrave; votre demande,
                afin de pr&eacute;server tant votre s&eacute;curit&eacute; que la n&ocirc;tre.
            </p>
            <p className="p4">&nbsp;</p>
            <p className="p4">&nbsp;</p>
            <p className="p4">Collecte des donn&eacute;es</p>
            <p className="p4">&nbsp;</p>
            <p className="p4">
                Nous recevons et stockons des donn&eacute;es vous concernant, telles que :
            </p>
            <p className="p4">&nbsp;</p>
            <ul className="ul1">
                <li className="li4">
                    Les donn&eacute;es que vous nous fournissez : nous collectons certaines
                    donn&eacute;es que vous nous fournissez, notamment :
                    <ul className="ul1">
                        <li className="li4">
                            vos nom, adresse e-mail, adresse ou code postal, identifiant, mode(s) de
                            paiement et donn&eacute;es de facturation, et num&eacute;ro de
                            t&eacute;l&eacute;phone. Dans certains pays, nous collectons un
                            num&eacute;ro d&apos;identification national, notamment &agrave; des
                            fins de facturation et de conformit&eacute; fiscale. Ces informations
                            peuvent &ecirc;tre collect&eacute;es de diff&eacute;rentes
                            mani&egrave;res, y compris lorsque vous utilisez notre service,
                            interagissez avec le service client&egrave;le ou participez &agrave; des
                            enqu&ecirc;tes ou &agrave; des promotions commerciales ;
                        </li>
                        <li className="li4">
                            des informations lorsque vous choisissez de publier des
                            &eacute;valuations, vos pr&eacute;f&eacute;rences et vos
                            param&egrave;tres de compte (y compris les pr&eacute;f&eacute;rences
                            d&eacute;finies dans la page &laquo;&nbsp;Compte&nbsp;&raquo; de notre
                            site web) ou que vous nous fournissez au moyen de notre service ou
                            autrement.
                        </li>
                    </ul>
                </li>
            </ul>
            <p className="p4">&nbsp;</p>
            <ul className="ul1">
                <li className="li4">
                    Les donn&eacute;es que nous collectons automatiquement : nous collectons
                    certaines donn&eacute;es sur vous et sur votre utilisation de notre service, sur
                    la mani&egrave;re dont vous interagissez avec nous et nos publicit&eacute;s,
                    ainsi que des donn&eacute;es relatives &agrave; votre ordinateur ou tout autre
                    appareil que vous utilisez pour acc&eacute;der &agrave; notre service (tels que
                    les consoles de jeu, smart TV, appareils mobiles, d&eacute;codeurs
                    num&eacute;riques et autres appareils de streaming). Cela comprend :
                    <ul className="ul1">
                        <li className="li4">
                            votre activit&eacute; sur le service CHEVAL TV, comme les titres que
                            vous avez s&eacute;lectionn&eacute;s, votre historique de lecture et les
                            recherches que vous avez effectu&eacute;es ;
                        </li>
                        <li className="li4">
                            vos interactions avec nos e-mails et SMS, ainsi qu&apos;avec les
                            messages que nous vous envoyons via des notifications Push et des
                            services de messagerie en ligne ;
                        </li>
                        <li className="li4">
                            des d&eacute;tails concernant vos interactions avec notre service
                            client&egrave;le, tels que la date, l&apos;heure et le motif de votre
                            prise de contact, les transcriptions de vos conversations en ligne et,
                            au cas o&ugrave; vous nous appelez, votre num&eacute;ro de
                            t&eacute;l&eacute;phone et les enregistrements de vos appels ;
                        </li>
                        <li className="li4">les ID ou identifiants uniques de vos appareils ;</li>
                        <li className="li4">
                            les identifiants r&eacute;initialisables de vos appareils
                            (&eacute;galement appel&eacute;s &laquo;&nbsp;identifiants
                            publicitaires&nbsp;&raquo;), comme ceux qui sont inclus sur certains
                            appareils mobiles, tablettes et appareils de streaming (pour en savoir
                            plus, veuillez consulter la section &laquo;&nbsp;Cookies et
                            publicit&eacute; en ligne&nbsp;&raquo; ci-dessous) ;
                        </li>
                        <li className="li4">
                            les caract&eacute;ristiques de vos appareils et logiciels (comme leur
                            type et leur configuration), des donn&eacute;es de connexion, des
                            statistiques sur les pages affich&eacute;es, des sources de renvoi (par
                            exemple, des URL de renvoi), des adresses IP (qui peuvent nous indiquer
                            votre localisation g&eacute;n&eacute;rale) et des informations standard
                            sur les logs des serveurs web et les navigateurs ;
                        </li>
                        <li className="li4">
                            des informations collect&eacute;es par des cookies, des balises web et
                            d&apos;autres technologies, y compris des donn&eacute;es publicitaires
                            (telles que les donn&eacute;es sur la disponibilit&eacute; et
                            l&apos;affichage des publicit&eacute;s, l&apos;URL du site, ainsi que la
                            date et l&apos;heure) (pour en savoir plus, veuillez consulter la
                            section &laquo;&nbsp;Cookies et publicit&eacute; en ligne&nbsp;&raquo;
                            ci-dessous).
                        </li>
                    </ul>
                </li>
            </ul>
            <p className="p4">&nbsp;</p>
            <ul className="ul1">
                <li className="li4">
                    Des informations provenant de partenaires : nous collectons des informations
                    provenant d&apos;autres soci&eacute;t&eacute;s avec lesquelles vous &ecirc;tes
                    li&eacute; (des &laquo;&nbsp;Partenaires&nbsp;&raquo;). Ces Partenaires peuvent
                    inclure (selon les services que vous utilisez) : votre fournisseur de
                    t&eacute;l&eacute;vision ou votre fournisseur d&apos;acc&egrave;s &agrave;
                    Internet, ou d&apos;autres fournisseurs d&apos;appareils de streaming qui
                    mettent notre service &agrave; disposition sur leurs appareils ; des
                    op&eacute;rateurs de t&eacute;l&eacute;phonie mobile ou d&apos;autres
                    soci&eacute;t&eacute;s qui vous fournissent des services et encaissent des
                    paiements pour le service CHEVAL TV qu&apos;ils nous redistribuent ; et des
                    fournisseurs de plateformes d&apos;assistance vocale qui permettent une
                    interaction avec notre service via des commandes vocales. Les informations que
                    nous fournissent ces Partenaires varient selon la nature de leurs services et
                    peuvent inclure :
                    <ul className="ul1">
                        <li className="li4">
                            les requ&ecirc;tes de recherche et commandes vocales que vous effectuez
                            au moyen d&apos;appareils ou de plateformes d&apos;assistance vocale de
                            ces Partenaires ;
                        </li>
                        <li className="li4">
                            des informations relatives &agrave; l&apos;activation du service, comme
                            votre adresse e-mail ou d&apos;autres coordonn&eacute;es ;
                        </li>
                        <li className="li4">
                            les ID ou autres identifiants uniques de vos appareils qui prennent en
                            charge l&apos;authentification des utilisateurs,
                            l&apos;exp&eacute;rience d&apos;inscription au service Cheval TV, le
                            traitement des paiements par ces Partenaires et la pr&eacute;sentation
                            qui vous est faite du contenu CHEVAL TV via des parties de
                            l&apos;interface utilisateur de ces Partenaires.
                        </li>
                    </ul>
                </li>
            </ul>
            <p className="p4">&nbsp;</p>
            <ul className="ul1">
                <li className="li4">
                    Des informations provenant d&apos;autres sources : nous obtenons
                    &eacute;galement des informations provenant d&apos;autres sources, que nous
                    prot&eacute;geons selon les pratiques d&eacute;crites dans la pr&eacute;sente
                    D&eacute;claration de confidentialit&eacute; et dans le respect des
                    &eacute;ventuelles restrictions suppl&eacute;mentaires impos&eacute;es par ces
                    sources de donn&eacute;es. Ces sources, qui varient au fil du temps, incluent :
                    <ul className="ul1">
                        <li className="li4">
                            des fournisseurs de services qui nous aident &agrave; d&eacute;terminer
                            une localisation en fonction de votre adresse IP, et nous permettent
                            ainsi de personnaliser notre service ou nous servent &agrave;
                            d&apos;autres fins, en accord avec la pr&eacute;sente D&eacute;claration
                            de confidentialit&eacute; ;
                        </li>
                        <li className="li4">
                            des prestataires de services de paiement qui nous fournissent des
                            informations sur les paiements, ou des mises &agrave; jour sur ces
                            informations, bas&eacute;es sur la relation qu&apos;ils entretiennent
                            avec vous ;
                        </li>
                        <li className="li4">
                            des fournisseurs de donn&eacute;es en ligne et hors ligne, par lesquels
                            nous obtenons des donn&eacute;es d&apos;ordre d&eacute;mographique, des
                            donn&eacute;es ciblant vos centres d&apos;int&eacute;r&ecirc;t et des
                            donn&eacute;es relatives &agrave; la publicit&eacute; en ligne ;
                        </li>
                        <li className="li4">
                            des sources accessibles au public, comme des bases de donn&eacute;es
                            nationales ouvertes.
                        </li>
                    </ul>
                </li>
            </ul>
            <p className="p4">&nbsp;</p>
            <p className="p4">&nbsp;</p>
            <p className="p4">Utilisation des donn&eacute;es</p>
            <p className="p4">&nbsp;</p>
            <p className="p4">
                Nous utilisons ces donn&eacute;es afin de fournir, analyser, administrer,
                am&eacute;liorer et personnaliser nos services et nos activit&eacute;s de marketing,
                afin de traiter votre abonnement, vos commandes et vos paiements, afin de
                communiquer avec vous sur ces sujets ou sur d&apos;autres th&egrave;mes et pour
                faire application de la l&eacute;gislation et de la r&eacute;glementation en vigueur
                et de nos Conditions g&eacute;n&eacute;rales. Ces donn&eacute;es peuvent par exemple
                nous servir &agrave; :
            </p>
            <p className="p4">&nbsp;</p>
            <ul className="ul1">
                <li className="li4">
                    D&eacute;terminer votre localisation g&eacute;n&eacute;rale, vous fournir un
                    contenu localis&eacute;, vous fournir des recommandations personnalis&eacute;es
                    de films et de s&eacute;ries susceptibles de vous int&eacute;resser, identifier
                    votre fournisseur d&apos;acc&egrave;s Internet et r&eacute;pondre &agrave; vos
                    requ&ecirc;tes et &agrave; vos demandes rapidement et efficacement.
                </li>
            </ul>
            <p className="p4">&nbsp;</p>
            <ul className="ul1">
                <li className="li4">
                    Coordonner avec nos Partenaires la mise &agrave; disposition du service CHEVAL
                    TV pour les utilisateurs et la communication aux non-utilisateurs
                    d&apos;informations sur la disponibilit&eacute; du service CHEVAL TV, en
                    fonction de la relation sp&eacute;cifique que vous entretenez avec ces
                    Partenaires.
                </li>
            </ul>
            <p className="p4">&nbsp;</p>
            <ul className="ul1">
                <li className="li4">
                    Emp&ecirc;cher, d&eacute;tecter et examiner des activit&eacute;s potentiellement
                    interdites ou ill&eacute;gales, dont les fraudes, et faire respecter nos
                    conditions d&apos;utilisation (notamment pour d&eacute;terminer
                    l&apos;admissibilit&eacute; &agrave; des essais gratuits).
                </li>
            </ul>
            <p className="p4">&nbsp;</p>
            <ul className="ul1">
                <li className="li4">
                    Analyser et comprendre notre public, am&eacute;liorer notre service (y compris
                    les exp&eacute;riences avec l&apos;interface utilisateur), et am&eacute;liorer
                    nos offres, la s&eacute;lection du contenu et les algorithmes de recommandation.
                </li>
            </ul>
            <p className="p4">&nbsp;</p>
            <ul className="ul1">
                <li className="li4">
                    Communiquer avec vous concernant notre service, afin de vous envoyer les
                    actualit&eacute;s de CHEVAL TV, des d&eacute;tails sur les nouvelles
                    fonctionnalit&eacute;s et les nouveaux contenus disponibles sur CHEVAL TV, des
                    offres sp&eacute;ciales, des annonces promotionnelles et des enqu&ecirc;tes de
                    satisfaction, ou afin de vous aider en cas de probl&egrave;me technique, par
                    exemple, pour r&eacute;initialiser votre mot de passe. Ces communications
                    peuvent &ecirc;tre r&eacute;alis&eacute;es par diff&eacute;rents moyens, par
                    exemple par e-mail, notifications Push, SMS, services de messagerie en ligne et
                    mise en correspondance d&apos;identifiants (tel que d&eacute;crit ci-dessous).
                    Veuillez consulter la section &laquo;&nbsp;Vos choix&nbsp;&raquo; de la
                    pr&eacute;sente D&eacute;claration de confidentialit&eacute; si vous souhaitez
                    savoir comment d&eacute;finir ou modifier vos pr&eacute;f&eacute;rences de
                    communication.
                </li>
            </ul>
            <p className="p4">&nbsp;</p>
            <p className="p4">&nbsp;</p>
            <p className="p4">Bases l&eacute;gales de traitement</p>
            <p className="p4">&nbsp;</p>
            <p className="p4">
                Le fondement juridique sur lequel nous nous fondons pour collecter et utiliser les
                donn&eacute;es personnelles d&eacute;crites dans la pr&eacute;sente
                D&eacute;claration de confidentialit&eacute; d&eacute;pend des donn&eacute;es
                personnelles concern&eacute;es et du contexte sp&eacute;cifique dans lequel nous les
                collectons et utilisons. Nous collectons normalement des donn&eacute;es personnelles
                de votre part&nbsp;:
            </p>
            <p className="p4">&nbsp;</p>
            <ul className="ul1">
                <li className="li4">
                    Lorsque nous en avons besoin pour ex&eacute;cuter un contrat avec vous (par
                    exemple, pour vous fournir nos services).
                </li>
            </ul>
            <p className="p4">&nbsp;</p>
            <ul className="ul1">
                <li className="li4">
                    Lorsque le traitement s&apos;effectue sur la base de nos int&eacute;r&ecirc;ts
                    l&eacute;gitimes et n&apos;est pas supplant&eacute; par vos droits et
                    libert&eacute;s fondamentales (par exemple, nos activit&eacute;s de marketing
                    direct en accord avec vos pr&eacute;f&eacute;rences).
                </li>
            </ul>
            <p className="p4">&nbsp;</p>
            <ul className="ul1">
                <li className="li4">
                    Lorsque vous nous donnez votre consentement pour le faire (par exemple, pour
                    vous permettre de participer &agrave; des activit&eacute;s relatives &agrave; la
                    recherche d&apos;informations sur les consommateurs, telles que des
                    enqu&ecirc;tes sp&eacute;cifiques et des groupes de discussion).
                </li>
            </ul>
            <p className="p4">&nbsp;</p>
            <ul className="ul1">
                <li className="li4">
                    Dans certains cas, nous pouvons aussi avoir l&apos;obligation l&eacute;gale de
                    collecter des donn&eacute;es personnelles vous concernant ou avoir autrement
                    besoin de ces donn&eacute;es personnelles pour prot&eacute;ger vos
                    int&eacute;r&ecirc;ts vitaux ou ceux d&apos;une autre personne (par exemple,
                    afin d&apos;&eacute;viter les fraudes li&eacute;es aux paiements ou pour
                    confirmer votre identit&eacute;).
                </li>
            </ul>
            <p className="p4">&nbsp;</p>
            <p className="p4">
                Si vous avez des questions concernant notre utilisation de vos donn&eacute;es
                personnelles (y compris concernant les fondements juridiques et les
                m&eacute;canismes de transfert sur lesquels nous nous fondons), des cookies et
                autres technologies similaires, veuillez contacter notre
                D&eacute;l&eacute;gu&eacute; &agrave; la protection des donn&eacute;es/Bureau de la
                protection des donn&eacute;es par e-mail &agrave; l&apos;adresse suivante :
                abonnement@chevaltv.fr
            </p>
            <p className="p4">&nbsp;</p>
            <p className="p4">&nbsp;</p>
            <p className="p4">Conservation de donn&eacute;es</p>
            <p className="p4">&nbsp;</p>
            <p className="p4">
                Vos donn&eacute;es &agrave; caract&egrave;re personnel collect&eacute;es sont
                conserv&eacute;es sur les serveurs d&rsquo;un prestataire situ&eacute; dans
                l&rsquo;Espace Economique Europ&eacute;en.
            </p>
            <p className="p4">&nbsp;</p>
            <p className="p4">
                Nous exigeons de ce prestataire qu&rsquo;il prenne, conform&eacute;ment &agrave; la
                r&eacute;glementation en vigueur, toutes les mesures organisationnelles et
                techniques permettant d&rsquo;assurer un niveau de protection ad&eacute;quat de vos
                donn&eacute;es &agrave; caract&egrave;re personnel.
            </p>
            <p className="p4">&nbsp;</p>
            <p className="p4">
                Nous conservons vos donn&eacute;es &agrave; caract&egrave;re personnel pour toute la
                dur&eacute;e d&rsquo;activation de votre compte personnel. A la suppression de votre
                compte, nous supprimerons l&rsquo;int&eacute;gralit&eacute; de vos donn&eacute;es
                &agrave; caract&egrave;re personnel dont nous disposons &agrave; l&rsquo;exception
                des donn&eacute;es qui nous sont n&eacute;cessaires pour remplir nos obligations
                contractuelles ou l&eacute;gales, notamment comptables. Les donn&eacute;es seront
                supprim&eacute;es &agrave; l&rsquo;issue du d&eacute;lai de prescription applicable
                en la mati&egrave;re.
            </p>
            <p className="p4">&nbsp;</p>
            <p className="p4">&nbsp;</p>
            <p className="p4">Partage de donn&eacute;es</p>
            <p className="p4">&nbsp;</p>
            <p className="p4">
                Nous partageons vos donn&eacute;es pour diff&eacute;rentes finalit&eacute;s avec des
                tiers, tel que d&eacute;crit ci-dessous :
            </p>
            <p className="p4">&nbsp;</p>
            <ul className="ul1">
                <li className="li4">
                    Au sein de notre soci&eacute;t&eacute; : nous partageons vos donn&eacute;es au
                    sein de notre soci&eacute;t&eacute; afin de traiter et stocker vos
                    donn&eacute;es, vous donner acc&egrave;s &agrave; nos services, vous fournir une
                    assistance &agrave; la client&egrave;le, prendre des d&eacute;cisions concernant
                    l&apos;am&eacute;lioration de nos services, d&eacute;velopper le contenu, et
                    &eacute;galement pour d&apos;autres finalit&eacute;s qui sont d&eacute;crites
                    dans la section &laquo;&nbsp;Utilisation des donn&eacute;es&nbsp;&raquo; de la
                    pr&eacute;sente D&eacute;claration de confidentialit&eacute;.
                </li>
            </ul>
            <p className="p4">&nbsp;</p>
            <ul className="ul1">
                <li className="li4">
                    Fournisseurs de services : nous faisons appel &agrave; d&apos;autres
                    soci&eacute;t&eacute;s, agents ou sous-traitants (&laquo;&nbsp;Fournisseurs de
                    services&nbsp;&raquo;) qui prennent en charge certains services en notre nom ou
                    qui nous aident &agrave; vous fournir nos services. Nous travaillons par exemple
                    avec des Fournisseurs de services dans le domaine du marketing, de la
                    publicit&eacute;, de la communication, de l&apos;infrastructure et des services
                    informatiques, pour personnaliser et am&eacute;liorer notre service, pour
                    traiter les transactions par carte bancaire ou par d&apos;autres moyens de
                    paiement, pour fournir un service d&apos;aide &agrave; la client&egrave;le, pour
                    recouvrer des cr&eacute;ances, pour analyser et am&eacute;liorer les
                    donn&eacute;es (notamment les donn&eacute;es sur les interactions des
                    utilisateurs avec notre service), ainsi que pour traiter et administrer des
                    enqu&ecirc;tes aupr&egrave;s des consommateurs. Dans le cadre de la prestation
                    de ces services, ces Fournisseurs de services sont susceptibles
                    d&apos;acc&eacute;der &agrave; vos donn&eacute;es personnelles ou &agrave;
                    d&apos;autres donn&eacute;es vous concernant. Nous ne les autorisons pas
                    &agrave; utiliser ni &agrave; divulguer vos donn&eacute;es personnelles, sauf
                    dans le cadre de la prestation de leurs services.
                </li>
            </ul>
            <p className="p4">&nbsp;</p>
            <ul className="ul1">
                <li className="li4">
                    Partenaires : comme d&eacute;crit ci-dessus, vous pouvez &ecirc;tre li&eacute;
                    &agrave; l&apos;un ou plusieurs de nos Partenaires, auquel cas nous sommes
                    susceptibles de partager certaines informations avec eux afin de coordonner la
                    mise &agrave; disposition du service CHEVAL
                    <span className="Apple-converted-space">&nbsp;&nbsp;</span>TV pour les
                    utilisateurs et la communication d&apos;informations sur la disponibilit&eacute;
                    du service CHEVAL TV. Par exemple, en fonction des services de Partenaires que
                    vous utilisez, nous sommes susceptibles de partager des informations :
                    <ul className="ul1">
                        <li className="li4">
                            afin de faciliter l&apos;encaissement par les Partenaires des paiements
                            pour le service CHEVAL TV qu&apos;ils nous redistribuent ;
                        </li>
                        <li className="li4">
                            avec des Partenaires fournisseurs de plateformes d&apos;assistance
                            vocale qui vous permettent d&apos;interagir avec notre service &agrave;
                            l&apos;aide de commandes vocales ;
                        </li>
                        <li className="li4">
                            afin que le contenu et les fonctionnalit&eacute;s disponibles sur le
                            service CHEVAL TV puissent vous &ecirc;tre sugg&eacute;r&eacute;s dans
                            l&apos;interface utilisateur des Partenaires. Pour les utilisateurs, ces
                            suggestions font partie du service CHEVAL TV et peuvent inclure des
                            recommandations personnalis&eacute;es.
                        </li>
                    </ul>
                </li>
            </ul>
            <p className="p4">&nbsp;</p>
            <ul className="ul1">
                <li className="li4">
                    Offres promotionnelles : nous pouvons vous sugg&eacute;rer des promotions ou
                    programmes conjoints. Pour ce faire, nous devons partager vos donn&eacute;es
                    avec des tiers. Pour prendre en charge ces types de promotion et d&apos;offre
                    incitative, nous sommes susceptibles de partager votre nom et d&apos;autres
                    informations. Veuillez noter que ces tiers sont responsables de leurs propres
                    pratiques de confidentialit&eacute;. Nous recueillons votre consentement
                    expr&egrave;s avant de partager vos donn&eacute;es avec ces tiers.
                </li>
            </ul>
            <p className="p4">&nbsp;</p>
            <ul className="ul1">
                <li className="li4">
                    Protection de CHEVAL TV et de ses Fournisseurs de services : CHEVAL
                    <span className="Apple-converted-space">&nbsp;&nbsp;</span>TV et ses
                    Fournisseurs de service peuvent divulguer et utiliser autrement vos
                    donn&eacute;es personnelles ou d&apos;autres donn&eacute;es lorsqu&apos;ils
                    consid&egrave;rent en toute bonne foi que cela est n&eacute;cessaire pour (a)
                    respecter toute loi, r&eacute;glementation, proc&eacute;dure juridique ou
                    demande du gouvernement, (b) faire respecter des conditions d&apos;utilisation
                    applicables, y compris pour constater d&apos;&eacute;ventuelles violations de
                    ces conditions, (c) d&eacute;celer, pr&eacute;venir ou traiter les
                    activit&eacute;s ill&eacute;gales ou pr&eacute;sum&eacute;es ill&eacute;gales
                    (notamment les fraudes li&eacute;es aux paiements), les atteintes &agrave; la
                    s&eacute;curit&eacute; ou tout probl&egrave;me d&apos;ordre technique, ou (d) se
                    pr&eacute;munir contre toute atteinte aux droits, aux biens ou &agrave; la
                    s&eacute;curit&eacute; de CHEVAL TV, ses utilisateurs ou le public, lorsque la
                    loi l&apos;exige ou le permet.
                </li>
            </ul>
            <p className="p4">&nbsp;</p>
            <ul className="ul1">
                <li className="li4">
                    Transfert d&apos;entreprise : que ce soit dans le cadre d&apos;une
                    r&eacute;organisation, d&apos;une restructuration, d&apos;une fusion, d&apos;une
                    vente ou de tout autre type de transfert d&apos;actifs, nous
                    transf&eacute;rerons les donn&eacute;es, y compris vos donn&eacute;es
                    personnelles, &agrave; condition que la partie r&eacute;ceptrice s&apos;engage
                    &agrave; respecter ces donn&eacute;es personnelles, en accord avec notre
                    D&eacute;claration de confidentialit&eacute;.
                </li>
            </ul>
            <p className="p4">&nbsp;</p>
            <p className="p4">
                Lorsque, dans le cadre du partage d&apos;informations, nous transf&eacute;rons des
                donn&eacute;es personnelles vers des pays situ&eacute;s hors de l&apos;Espace
                &eacute;conomique europ&eacute;en et d&apos;autres r&eacute;gions qui disposent
                d&apos;une l&eacute;gislation en mati&egrave;re de protection des donn&eacute;es,
                nous nous assurons que ces transferts soient r&eacute;alis&eacute;s
                conform&eacute;ment &agrave; la pr&eacute;sente D&eacute;claration de
                confidentialit&eacute; et dans le respect de la l&eacute;gislation applicable en
                mati&egrave;re de protection des donn&eacute;es.
            </p>
            <p className="p4">&nbsp;</p>
            <p className="p4">
                Vous pouvez &eacute;galement choisir de partager vos donn&eacute;es comme suit :
            </p>
            <p className="p4">&nbsp;</p>
            <ul className="ul1">
                <li className="li4">
                    Certaines parties de notre service peuvent contenir un outil qui vous permet de
                    partager des informations par e-mail, par SMS, &agrave; l&apos;aide
                    d&apos;applications sociales ou d&apos;autres applications de partage, par le
                    biais des clients et applications install&eacute;s sur votre appareil
                    intelligent.
                </li>
            </ul>
            <p className="p4">&nbsp;</p>
            <ul className="ul1">
                <li className="li4">
                    Les plug-ins sociaux et technologies similaires vous permettent de partager des
                    informations.
                </li>
            </ul>
            <p className="p4">&nbsp;</p>
            <p className="p4">
                Les plug-ins sociaux et les applications sociales sont exploit&eacute;s par les
                r&eacute;seaux sociaux eux-m&ecirc;mes et sont soumis &agrave; leurs conditions
                d&apos;utilisation et &agrave; leurs politiques de confidentialit&eacute;.
            </p>
            <p className="p4">&nbsp;</p>
            <p className="p4">&nbsp;</p>
            <p className="p4">Acc&egrave;s &agrave; votre compte et &agrave; votre profil</p>
            <p className="p4">&nbsp;</p>
            <p className="p4">
                Afin de faciliter l&apos;acc&egrave;s &agrave; votre compte, vous pouvez cocher la
                case &laquo;&nbsp;Se souvenir de moi sur cet appareil&nbsp;&raquo; lorsque vous vous
                connectez au site web. Cette fonction utilise une technologie qui nous permet de
                faciliter l&apos;acc&egrave;s &agrave; votre compte et de simplifier votre gestion
                du service CHEVAL TV sans que vous ayez &agrave; entrer de nouveau un mot de passe
                ou un autre moyen d&apos;identification lorsque votre navigateur retourne sur le
                service.
            </p>
            <p className="p4">&nbsp;</p>
            <p className="p4">
                Pour supprimer l&apos;acc&egrave;s &agrave; votre compte CHEVAL TV sur vos appareils
                : (a) acc&eacute;dez &agrave; la section &laquo;&nbsp;Compte&nbsp;&raquo; de notre
                site web, s&eacute;lectionnez &laquo;&nbsp;Se d&eacute;connecter de tous les
                appareils&nbsp;&raquo;, puis suivez les instructions pour d&eacute;sactiver vos
                appareils (notez que la d&eacute;sactivation n&apos;est pas forc&eacute;ment
                imm&eacute;diate) ou (b) supprimez les param&egrave;tres CHEVAL TV sur
                l&apos;appareil (la proc&eacute;dure varie selon l&apos;appareil et l&apos;option
                n&apos;est pas propos&eacute;e par tous les appareils). Dans la mesure du possible,
                les utilisateurs d&apos;appareils publics ou partag&eacute;s doivent se
                d&eacute;connecter &agrave; la fin de chaque visite. Si vous vendez ou rendez un
                ordinateur ou un appareil compatible avec CHEVAL TV, vous devez vous
                d&eacute;connecter et d&eacute;sactiver l&apos;appareil au pr&eacute;alable. Si vous
                ne pr&eacute;servez pas la s&eacute;curit&eacute; de votre mot de passe ou de votre
                appareil, ou si vous ne vous d&eacute;connectez pas et ne d&eacute;sactivez pas
                votre appareil, d&apos;autres utilisateurs pourront acc&eacute;der &agrave; votre
                compte, y compris &agrave; vos donn&eacute;es personnelles.
            </p>
            <p className="p4">&nbsp;</p>
            <p className="p4">&nbsp;</p>
            <p className="p4">Vos choix</p>
            <p className="p4">&nbsp;</p>
            <p className="p4">
                E-mails et SMS. Si vous souhaitez ne plus recevoir certains types de communications
                de CHEVAL TV par e-mail ou par SMS vous pouvez aussi cliquer sur le lien
                &laquo;&nbsp;Se d&eacute;sabonner&nbsp;&raquo; dans l&apos;e-mail ou r&eacute;pondre
                STOP (ou l&apos;indication re&ccedil;ue dans le message) au SMS. Veuillez noter que
                vous ne pouvez pas vous d&eacute;sabonner des communications en rapport avec le
                service, notamment des messages concernant les transactions sur votre compte.
            </p>
            <p className="p4">&nbsp;</p>
            <p className="p4">
                Notifications Push. Vous pouvez choisir de recevoir des notifications Push de la
                part de CHEVAL TV. Avec certains syst&egrave;mes d&apos;exploitation, vous serez
                automatiquement inscrit aux notifications. Si vous d&eacute;cidez ensuite que vous
                ne souhaitez plus recevoir ces notifications, vous pouvez d&eacute;sactiver cette
                fonctionnalit&eacute; dans les r&eacute;glages de votre appareil mobile.
            </p>
            <p className="p4">&nbsp;</p>
            <p className="p4">
                WhatsApp. Si vous avez activ&eacute; les messages WhatsApp en lien avec votre profil
                ou compte CHEVAL TV, mais ne souhaitez plus recevoir de tels messages, vous pouvez
                modifier vos param&egrave;tres dans l&apos;application WhatsApp afin de les bloquer.
            </p>
            <p className="p4">&nbsp;</p>
            <p className="p4">
                Publicit&eacute;s cibl&eacute;es par centres d&apos;int&eacute;r&ecirc;t. Les
                publicit&eacute;s cibl&eacute;es par centres d&apos;int&eacute;r&ecirc;t sont des
                publicit&eacute;s en ligne adapt&eacute;es &agrave; l&apos;utilisation que vous
                faites de divers sites web et applications sur Internet. Si vous utilisez un
                navigateur, les cookies et les balises web nous permettent de collecter des
                informations qui nous aident &agrave; cerner vos centres
                d&apos;int&eacute;r&ecirc;t. Si vous utilisez un appareil mobile, une tablette ou un
                appareil de streaming qui inclut un identifiant r&eacute;initialisable, ce dernier
                peut nous aider &agrave; identifier vos centres d&apos;int&eacute;r&ecirc;t
                potentiels. Pour en savoir plus sur les choix qui s&apos;offrent &agrave; vous
                concernant les publicit&eacute;s de CHEVAL TV cibl&eacute;es par centres
                d&apos;int&eacute;r&ecirc;t, veuillez consulter la section &laquo;&nbsp;Cookies et
                publicit&eacute; en ligne&nbsp;&raquo; ci-dessous.
            </p>
            <p className="p4">&nbsp;</p>
            <p className="p4">
                Communications bas&eacute;es sur la mise en correspondance d&apos;identifiants.
                Certaines applications et certains sites tiers nous permettent de proposer &agrave;
                nos utilisateurs des promotions en ligne sur nos titres et services en envoyant un
                identifiant confidentiel &agrave; ces tiers. Cet identifiant est issu de la
                conversion des donn&eacute;es d&apos;origine (comme une adresse e-mail ou un
                num&eacute;ro de t&eacute;l&eacute;phone) en une valeur permettant de ne pas les
                divulguer. Le tiers compare cet identifiant &agrave; ceux pr&eacute;sents dans sa
                base de donn&eacute;es et une correspondance est &eacute;tablie uniquement si vous
                avez utilis&eacute; ce m&ecirc;me identifiant (comme une adresse e-mail) avec CHEVAL
                TV et le tiers. En cas de correspondance, CHEVAL
                <span className="Apple-converted-space">&nbsp;&nbsp;</span>TV peut alors
                d&eacute;cider de vous envoyer une communication promotionnelle sur
                l&apos;application ou le site tiers en question. Vous pouvez d&eacute;sactiver cette
                option &agrave; la page &laquo;&nbsp;Communications marketing&nbsp;&raquo; de la
                section &laquo;&nbsp;Compte&nbsp;&raquo; de notre site web.
            </p>
            <p className="p4">&nbsp;</p>
            <p className="p4">&nbsp;</p>
            <p className="p4">Vos droits et informations</p>
            <p className="p4">&nbsp;</p>
            <p className="p4">
                Vous pouvez demander l&apos;acc&egrave;s &agrave; vos donn&eacute;es personnelles ou
                corriger ou mettre &agrave; jour des donn&eacute;es erron&eacute;es ou
                obsol&egrave;tes que nous conservons sur vous.
            </p>
            <p className="p4">&nbsp;</p>
            <p className="p4">
                Le moyen le plus simple de le faire est d&apos;acc&eacute;der &agrave; la section
                &laquo;&nbsp;Compte&nbsp;&raquo; de notre site web, o&ugrave; vous pouvez consulter
                et mettre &agrave; jour de nombreuses informations sur votre compte, y compris vos
                coordonn&eacute;es, vos informations de paiement CHEVAL
                <span className="Apple-converted-space">&nbsp;&nbsp;</span>TV et divers
                renseignements connexes sur votre compte (comme le contenu que vous avez
                visionn&eacute; et &eacute;valu&eacute;). Vous devez vous identifier pour
                acc&eacute;der &agrave; la section &laquo;&nbsp;Compte&nbsp;&raquo;. Vous pouvez
                &eacute;galement demander la suppression des donn&eacute;es personnelles que nous
                conservons sur vous.
            </p>
            <p className="p4">&nbsp;</p>
            <p className="p4">
                Pour &eacute;mettre de telles demandes, ou si vous avez d&apos;autres questions
                concernant nos pratiques de confidentialit&eacute;, veuillez contacter notre
                D&eacute;l&eacute;gu&eacute; &agrave; la protection des donn&eacute;es/Bureau de la
                protection des donn&eacute;es par e-mail &agrave; l&apos;adresse suivante :
                abonnement@chevaltv.fr Nous r&eacute;pondons &agrave; toutes les demandes que nous
                recevons de la part des personnes souhaitant exercer leurs droits en mati&egrave;re
                de protection des donn&eacute;es, conform&eacute;ment aux lois en vigueur sur la
                protection des donn&eacute;es. Veuillez aussi vous reporter &agrave; la section
                &laquo;&nbsp;Vos choix&nbsp;&raquo; dans la pr&eacute;sente D&eacute;claration de
                confidentialit&eacute; pour conna&icirc;tre les options suppl&eacute;mentaires
                concernant vos donn&eacute;es.
            </p>
            <p className="p4">&nbsp;</p>
            <p className="p4">
                Vous pouvez vous opposer au traitement de vos donn&eacute;es personnelles, nous
                demander de limiter le traitement de vos donn&eacute;es personnelles ou demander la
                portabilit&eacute; de vos donn&eacute;es personnelles. Si nous avons collect&eacute;
                et trait&eacute; vos donn&eacute;es personnelles avec votre consentement, vous
                pouvez retirer votre consentement &agrave; tout moment. Le retrait de votre
                consentement n&apos;affecte pas la lic&eacute;it&eacute; de tout &eacute;ventuel
                traitement que nous aurions r&eacute;alis&eacute; au pr&eacute;alable et
                n&apos;affecte pas non plus le traitement de vos donn&eacute;es personnelles
                r&eacute;alis&eacute; sur un fondement l&eacute;gal de traitement autre que le
                consentement. Vous avez le droit de d&eacute;poser une plainte aupr&egrave;s
                d&apos;une autorit&eacute; de protection des donn&eacute;es au sujet de la collecte
                et de l&apos;utilisation de vos donn&eacute;es personnelles. Notre
                &eacute;tablissement principal au sein de l&apos;Union europ&eacute;enne se trouve
                en France.
            </p>
            <p className="p4">&nbsp;</p>
            <p className="p4">
                Nous pouvons conserver certaines informations, si les lois et r&egrave;glements en
                vigueur l&apos;exigent ou le permettent, notamment pour honorer vos choix, &agrave;
                des fins de facturation ou d&apos;archivage et pour satisfaire les objectifs
                d&eacute;crits dans la pr&eacute;sente D&eacute;claration de confidentialit&eacute;.
                Nous prenons des mesures raisonnables pour d&eacute;truire ou d&eacute;personnaliser
                les donn&eacute;es personnelles de mani&egrave;re s&eacute;curis&eacute;e
                lorsqu&apos;elles ne sont plus n&eacute;cessaires.
            </p>
            <p className="p4">&nbsp;</p>
            <p className="p4">&nbsp;</p>
            <p className="p4">S&eacute;curit&eacute;</p>
            <p className="p4">&nbsp;</p>
            <p className="p4">
                Nous mettons en &oelig;uvre des mesures administratives, logiques, physiques et
                organisationnelles raisonnables pour prot&eacute;ger la s&eacute;curit&eacute; de
                vos donn&eacute;es personnelles contre le vol ainsi que l&apos;acc&egrave;s,
                l&apos;utilisation et la modification non autoris&eacute;s. Ces mesures sont
                con&ccedil;ues pour assurer un niveau de s&eacute;curit&eacute; adapt&eacute; aux
                risques qui accompagnent le traitement de vos donn&eacute;es personnelles.
            </p>
            <p className="p4">&nbsp;</p>
            <p className="p4">&nbsp;</p>
            <p className="p4">Autres sites web, plateformes et applications</p>
            <p className="p4">&nbsp;</p>
            <p className="p4">
                Le service CHEVAL TV peut &ecirc;tre propos&eacute; sur et/ou utiliser des
                fonctionnalit&eacute;s (comme le contr&ocirc;le vocal) g&eacute;r&eacute;es par des
                plateformes tierces ou contenir des liens vers des sites exploit&eacute;s par des
                tiers dont la politique en mati&egrave;re de traitement des donn&eacute;es
                personnelles peut &ecirc;tre diff&eacute;rente de la n&ocirc;tre. Par exemple, vous
                pouvez acc&eacute;der au service CHEVAL TV par le biais de plateformes comme les
                consoles de jeu, smart TV, appareils mobiles, d&eacute;codeurs num&eacute;riques
                ainsi que par d&apos;autres dispositifs connect&eacute;s. Ces sites web et
                plateformes sont r&eacute;gis par des politiques de confidentialit&eacute; ou des
                donn&eacute;es, des d&eacute;clarations de confidentialit&eacute;, des notices et
                conditions d&apos;utilisation distinctes et ind&eacute;pendantes que nous vous
                recommandons de lire attentivement. Vous pouvez aussi rencontrer des applications
                tierces qui interagissent avec le service CHEVAL TV.
            </p>
            <p className="p4">&nbsp;</p>
            <p className="p4">
                Pendant votre navigation sur le service, vous &ecirc;tes susceptible de voir des
                contenus qui comportent des liens vers des applications mobiles et / ou sites
                internet de tiers. Nous ne pouvons ni acc&eacute;der ni contr&ocirc;ler les cookies
                ou les autres fonctions utilis&eacute;es par les applications mobiles et / ou sites
                internet des tiers, et les proc&eacute;dures de ces applications mobiles externes ne
                sont pas r&eacute;gies par la pr&eacute;sente D&eacute;claration de
                confidentialit&eacute;. Par cons&eacute;quent, il vous appartient de contacter et /
                ou de consulter directement les conditions g&eacute;n&eacute;rales
                d&rsquo;utilisation et d&eacute;claration de confidentialit&eacute; de ces
                applications mobiles et / ou sites internet de ces tiers pour obtenir des
                informations suppl&eacute;mentaires concernant leurs proc&eacute;dures de protection
                des donn&eacute;es &agrave; caract&egrave;re personnel.
            </p>
            <p className="p4">&nbsp;</p>
            <p className="p4">&nbsp;</p>
            <p className="p4">Mineurs</p>
            <p className="p4">&nbsp;</p>
            <p className="p4">
                Vous devez avoir 18 ans ou plus pour vous abonner au service CHEVAL TV. Dans
                certains pays, l&apos;&acirc;ge l&eacute;gal de la majorit&eacute; peut &ecirc;tre
                sup&eacute;rieur &agrave; 18 ans, auquel cas vous devez satisfaire &agrave; cette
                exigence pour pouvoir devenir membre. Les mineurs de moins de 18 ans peuvent
                utiliser le service, mais uniquement avec la participation, avec l&apos;accord et
                sous la surveillance d&apos;un parent ou d&apos;un tuteur l&eacute;gal.
            </p>
            <p className="p4">&nbsp;</p>
            <p className="p4">&nbsp;</p>
            <p className="p4">
                Modifications apport&eacute;es &agrave; la pr&eacute;sente D&eacute;claration de
                confidentialit&eacute;
            </p>
            <p className="p4">&nbsp;</p>
            <p className="p4">
                La pr&eacute;sente D&eacute;claration de confidentialit&eacute; sera mise &agrave;
                jour de temps &agrave; autre pour tenir compte des &eacute;volutions l&eacute;gales,
                r&eacute;glementaires et op&eacute;rationnelles. Nous vous aviserons de ces
                modifications (et de leur date d&apos;entr&eacute;e en vigueur) conform&eacute;ment
                &agrave; la loi. Votre utilisation continue du service CHEVAL TV apr&egrave;s la
                prise d&apos;effet de ces modifications vaut reconnaissance et (le cas
                &eacute;ch&eacute;ant) acceptation de votre part de ces modifications. Si vous ne
                souhaitez pas reconna&icirc;tre ou accepter les modifications apport&eacute;es
                &agrave; la pr&eacute;sente D&eacute;claration de confidentialit&eacute;, vous devez
                cesser d&apos;utiliser le service CHEVAL
                <span className="Apple-converted-space">&nbsp;&nbsp;</span>TV. Pour conna&icirc;tre
                la date de la derni&egrave;re r&eacute;vision du pr&eacute;sent document, consultez
                la section &laquo;&nbsp;Derni&egrave;re mise&nbsp;&raquo; &agrave; jour ci-dessous.
            </p>
            <p className="p4">&nbsp;</p>
            <p className="p4">&nbsp;</p>
            <p className="p4">Cookies et publicit&eacute; en ligne</p>
            <p className="p4">&nbsp;</p>
            <p className="p4">
                CHEVAL TV et ses Fournisseurs de services utilisent des cookies et d&apos;autres
                technologies (comme des balises web), ainsi que des identifiants
                r&eacute;initialisables, pour diff&eacute;rentes raisons. Ces technologies nous
                permettent notamment de faciliter votre acc&egrave;s &agrave; nos services parce que
                nous nous souvenons de vous chaque fois que vous vous connectez, ainsi que
                d&apos;assurer et d&apos;analyser nos prestations. Nous utilisons &eacute;galement
                des cookies et des identifiants r&eacute;initialisables pour conna&icirc;tre plus
                pr&eacute;cis&eacute;ment nos utilisateurs, mieux comprendre leurs
                int&eacute;r&ecirc;ts et cibler nos publicit&eacute;s et nos campagnes marketing.
                Cette section vise &agrave; vous informer de l&apos;utilisation que nous faisons de
                telles technologies. Elle explique les types de technologies dont nous nous servons,
                leur utilit&eacute; et les choix dont vous disposez concernant leur utilisation.
            </p>
            <p className="p4">&nbsp;</p>
            <p className="p4">&nbsp;</p>
            <ul className="ul1">
                <li className="li4">Que sont les cookies ?</li>
            </ul>
            <p className="p4">&nbsp;</p>
            <p className="p4">
                Les cookies sont de petits fichiers de donn&eacute;es qui sont stock&eacute;s sur
                votre appareil lorsque vous visitez et utilisez des sites web et des services en
                ligne. Ils sont utilis&eacute;s couramment pour faire fonctionner les sites web ou
                pour en optimiser le fonctionnement, pour fournir des renseignements et pour
                personnaliser les services et la publicit&eacute;. Les cookies ne sont pas les
                seules technologies exploitables &agrave; ces fins. Nous utilisons &eacute;galement
                d&apos;autres types de technologies similaires. Consultez les d&eacute;tails
                ci-dessous pour en savoir plus et obtenir des exemples.
            </p>
            <p className="p4">&nbsp;</p>
            <p className="p4">&nbsp;</p>
            <ul className="ul1">
                <li className="li4">
                    Que sont les identifiants d&apos;appareils r&eacute;initialisables ?
                </li>
            </ul>
            <p className="p4">&nbsp;</p>
            <p className="p4">
                Les identifiants r&eacute;initialisables (&eacute;galement appel&eacute;s
                &laquo;&nbsp;identifiants publicitaires&nbsp;&raquo;), semblables aux cookies, se
                trouvent sur de nombreux appareils mobiles et tablettes (il s&apos;agit par exemple
                des identifiants pour annonceurs ou IDFA sur les appareils iOS et des identifiants
                publicitaires Google sur les appareils Android), ainsi que sur certains appareils de
                streaming. &Agrave; l&apos;instar des cookies, les identifiants
                r&eacute;initialisables permettent de rendre le contenu publicitaire en ligne plus
                pertinent.
            </p>
            <p className="p4">&nbsp;</p>
            <p className="p4">&nbsp;</p>
            <ul className="ul1">
                <li className="li4">
                    Pourquoi CHEVAL TV utilise-t-il des cookies et des identifiants
                    r&eacute;initialisables ?
                </li>
            </ul>
            <p className="p4">&nbsp;</p>
            <ul className="ul1">
                <li className="li4">
                    Cookies n&eacute;cessaires : ces cookies sont essentiels au fonctionnement de
                    notre site web et de notre service en ligne. Par exemple, CHEVAL TV et ses
                    Fournisseurs de services peuvent utiliser ce type de cookie pour authentifier
                    nos utilisateurs et les identifier lorsqu&apos;ils sont connect&eacute;s
                    &agrave; nos sites Web et &agrave; nos applications, afin de leur fournir notre
                    service. Ces cookies nous permettent &eacute;galement de faire respecter nos
                    Conditions d&apos;utilisation, d&apos;&eacute;viter les fraudes et
                    d&apos;assurer la s&eacute;curit&eacute; de notre service.
                </li>
            </ul>
            <p className="p4">&nbsp;</p>
            <ul className="ul1">
                <li className="li4">
                    Cookies de performance et de fonctionnalit&eacute; : ces cookies ne sont pas
                    essentiels, mais ils nous aident &agrave; personnaliser votre exp&eacute;rience
                    en ligne sur CHEVAL TV et &agrave; l&apos;am&eacute;liorer. Ils nous permettent,
                    par exemple, de m&eacute;moriser vos pr&eacute;f&eacute;rences et ils vous
                    &eacute;vitent de devoir ressaisir des donn&eacute;es que vous nous avez
                    d&eacute;j&agrave; communiqu&eacute;es (notamment lors de votre inscription).
                    Nous utilisons &eacute;galement ce type de cookie pour collecter des
                    donn&eacute;es (comme les pages les plus populaires, les taux de conversion, les
                    habitudes de lecture ou le nombre de clics) sur la mani&egrave;re dont les
                    internautes utilisent le service CHEVAL TV, afin d&apos;am&eacute;liorer et de
                    personnaliser notre site web et notre service, et pour r&eacute;aliser des
                    &eacute;tudes de march&eacute;. La suppression de ces types de cookies peut
                    limiter les fonctionnalit&eacute;s de notre service.
                </li>
            </ul>
            <p className="p4">&nbsp;</p>
            <ul className="ul1">
                <li className="li4">
                    Cookies publicitaires et identifiants r&eacute;initialisables : les cookies et
                    identifiants r&eacute;initialisables exploitent des informations sur votre
                    utilisation de ce site web, d&apos;applications et d&apos;autres sites Web,
                    ainsi que sur votre r&eacute;action aux publicit&eacute;s et aux e-mails, dans
                    le but de vous proposer des publicit&eacute;s susceptibles de vous
                    int&eacute;resser davantage. Ces publicit&eacute;s sont qualifi&eacute;es de
                    &laquo;&nbsp;publicit&eacute;s cibl&eacute;es par centres
                    d&apos;int&eacute;r&ecirc;t&nbsp;&raquo;. De nombreux cookies publicitaires
                    associ&eacute;s &agrave; notre service appartiennent &agrave; nos Fournisseurs
                    de services.
                </li>
            </ul>
            <p className="p4">&nbsp;</p>
            <p className="p4">&nbsp;</p>
            <ul className="ul1">
                <li className="li4">
                    Comment puis-je exercer mon choix concernant l&apos;utilisation des cookies et
                    des identifiants r&eacute;initialisables ?
                </li>
            </ul>
            <p className="p4">&nbsp;</p>
            <p className="p4">
                Votre consentement pr&eacute;alable est requis avant toute lecture ou tout
                d&eacute;p&ocirc;t de certains types de cookies, dont les cookies de performance et
                de fonctionnalit&eacute; et les cookies publicitaires et identifiants
                r&eacute;initialisables. Nous recueillons votre consentement au moyen du bandeau
                pr&eacute;sent lors de votre premi&egrave;re navigation sur le Site. Ces cookies ont
                une dur&eacute;e de vie de 13 mois maximum. Au-del&agrave; de cette p&eacute;riode,
                votre consentement sera &agrave; nouveau requis avant le d&eacute;p&ocirc;t de tout
                nouveau cookie.
            </p>
            <p className="p4">&nbsp;</p>
            <p className="p4">
                Pour en savoir plus sur les cookies cr&eacute;&eacute;s par notre site web et sur
                les autres m&eacute;thodes de suivi en ligne (notamment la collecte de
                donn&eacute;es par des tiers sur vos activit&eacute;s en ligne au fil du temps et
                via des services en ligne ou des sites tiers afin d&apos;optimiser les
                publicit&eacute;s cibl&eacute;es par centres d&apos;int&eacute;r&ecirc;t) et pour
                exercer votre choix les concernant contactez nous &agrave; abonnement@chevaltv.fr
            </p>
            <p className="p4">&nbsp;</p>
            <p className="p4">
                Vous pouvez &eacute;galement d&eacute;sactiver tout ou partie de ces cookies en
                changeant les param&egrave;tres de votre navigateur. Cependant, le refus
                d&rsquo;utilisation des cookies peut limiter voire emp&ecirc;cher
                l&rsquo;utilisation de certaines fonctionnalit&eacute;s du Site.
            </p>
            <p className="p4">&nbsp;</p>
            <p className="p4">&nbsp;</p>
            <ul className="ul1">
                <li className="li4">
                    Comment CHEVAL<span className="Apple-converted-space">&nbsp;&nbsp;</span>TV
                    utilise-t-il les balises Web et les autres technologies ?
                </li>
            </ul>
            <p className="p4">&nbsp;</p>
            <p className="p4">
                Les cookies sont souvent associ&eacute;s &agrave; des balises web (&eacute;galement
                appel&eacute;es &laquo;&nbsp;GIF invisibles&nbsp;&raquo; ou
                &laquo;&nbsp;pixels-espions&nbsp;&raquo;). CHEVAL TV et ses Fournisseurs de services
                sont susceptibles d&apos;utiliser des balises Web aux m&ecirc;mes fins que les
                cookies, par exemple pour comprendre et am&eacute;liorer l&apos;utilisation de notre
                service, optimiser les performances du site, analyser le trafic des internautes et
                les actions effectu&eacute;es sur notre site, ou encore comprendre les interactions
                avec nos campagnes commerciales (notamment les e-mails et les publicit&eacute;s en
                ligne sur des sites tiers). Comme les balises Web sont g&eacute;n&eacute;ralement
                associ&eacute;es &agrave; des cookies, dans la plupart des cas, le fait de refuser
                les cookies amoindrit l&apos;efficacit&eacute; des balises web qui leur sont
                associ&eacute;es.
            </p>
            <p className="p4">&nbsp;</p>
            <p className="p4">
                Nous utilisons d&apos;autres technologies semblables aux cookies, comme le stockage
                et les plug-ins pour navigateurs web (par exemple, HTML5, IndexedDB et WebSQL).
                &Agrave; l&apos;instar des cookies, certaines de ces technologies peuvent stocker de
                petites quantit&eacute;s de donn&eacute;es sur votre appareil. Nous sommes
                susceptibles d&apos;utiliser ces technologies, entre autres, pour les m&ecirc;mes
                finalit&eacute;s que les cookies : pour faire respecter nos conditions, pour
                &eacute;viter les fraudes et pour analyser l&apos;utilisation de notre service. Vous
                pouvez exercer votre choix concernant l&apos;utilisation de ces technologies de
                diff&eacute;rentes mani&egrave;res. Par exemple, les navigateurs les plus connus
                permettent de purger leur stockage, g&eacute;n&eacute;ralement via les
                param&egrave;tres ou les pr&eacute;f&eacute;rences. Pour en savoir plus, veuillez
                consulter l&apos;aide en ligne ou la section de support de votre navigateur. Vous
                pouvez &eacute;galement d&eacute;sactiver d&apos;autres technologies comme le
                stockage Silverlight directement dans l&apos;application.
            </p>
            <p className="p4">&nbsp;</p>
            <p className="p5">R&egrave;gles de s&eacute;curit&eacute;</p>
            <p className="p4">&nbsp;</p>
            <p className="p4">
                La s&eacute;curit&eacute; fait partie int&eacute;grante de nos services en vue de
                prot&eacute;ger vos informations
            </p>
            <p className="p4">&nbsp;</p>
            <p className="p4">
                Tous les produits CHEVAL<span className="Apple-converted-space">&nbsp;&nbsp;</span>
                TV int&egrave;grent de puissantes fonctionnalit&eacute;s de s&eacute;curit&eacute;
                qui prot&egrave;gent vos informations en continu. Les renseignements que nous
                collectons via nos services nous permettent de d&eacute;tecter et de bloquer
                automatiquement les menaces qui p&egrave;sent sur votre s&eacute;curit&eacute;. Si
                nous d&eacute;tectons une activit&eacute; suspecte importante, nous vous en
                informerons et vous donnerons des conseils pour am&eacute;liorer votre
                s&eacute;curit&eacute;.
            </p>
            <p className="p4">&nbsp;</p>
            <p className="p4">
                Nous mettons en &oelig;uvre toutes les mesures de s&eacute;curit&eacute;
                n&eacute;cessaires pour vous prot&eacute;ger, contre tout acc&egrave;s, toute
                modification, divulgation ou destruction non autoris&eacute;s des informations que
                nous d&eacute;tenons. Ces mesures sont notamment les suivantes :
            </p>
            <p className="p4">&nbsp;</p>
            <ul className="ul1">
                <li className="li4">
                    Nous chiffrons vos donn&eacute;es afin d&rsquo;en garantir la
                    confidentialit&eacute; dans le cadre de transferts.
                </li>
            </ul>
            <p className="p4">&nbsp;</p>
            <ul className="ul1">
                <li className="li4">
                    Nous proposons de nombreuses fonctionnalit&eacute;s de s&eacute;curit&eacute;,
                    telles que la navigation s&eacute;curis&eacute;e, la v&eacute;rification des
                    param&egrave;tres de s&eacute;curit&eacute; et la validation en deux
                    &eacute;tapes, pour vous aider &agrave; prot&eacute;ger votre compte.
                </li>
            </ul>
            <p className="p4">&nbsp;</p>
            <ul className="ul1">
                <li className="li4">
                    Nous menons des audits internes sur la collecte, le stockage et le traitement
                    des donn&eacute;es, y compris les mesures de s&eacute;curit&eacute; physiques,
                    afin d&rsquo;emp&ecirc;cher tout acc&egrave;s non autoris&eacute; &agrave; nos
                    syst&egrave;mes.
                </li>
            </ul>
            <p className="p4">&nbsp;</p>
            <ul className="ul1">
                <li className="li4">
                    L&rsquo;acc&egrave;s aux informations personnelles est strictement
                    r&eacute;serv&eacute; aux employ&eacute;s, sous-traitants et agents de Google
                    qui ont besoin d&rsquo;y acc&eacute;der afin de les traiter en notre nom. Toute
                    personne y ayant acc&egrave;s est soumise &agrave; de strictes obligations de
                    confidentialit&eacute; et est susceptible de faire l&rsquo;objet de sanctions
                    disciplinaires pouvant aller jusqu&rsquo;au licenciement en cas de manquement
                    &agrave; ces obligations.
                </li>
            </ul>
            <p className="p4">&nbsp;</p>
            <p className="p4">&nbsp;</p>
            <p className="p4">
                CONFORMIT&Eacute; ET COOP&Eacute;RATION AVEC DES ORGANISMES DE R&Eacute;GLEMENTATION
            </p>
            <p className="p4">&nbsp;</p>
            <p className="p4">
                Nous r&eacute;visons r&eacute;guli&egrave;rement les pr&eacute;sentes R&egrave;gles
                de confidentialit&eacute; et nous nous assurons que nous traitons vos informations
                conform&eacute;ment &agrave; celles-ci.
            </p>
            <p className="p4">&nbsp;</p>
            <p className="p4">&nbsp;</p>
            <p className="p4">Transferts de donn&eacute;es</p>
            <p className="p4">&nbsp;</p>
            <p className="p4">
                Nous disposons de serveurs Google Cloud &amp; OVH situ&eacute;s dans l&rsquo;Espace
                &Eacute;conomique Europ&eacute;en. Il est donc possible que vos informations soient
                trait&eacute;es par des serveurs situ&eacute;s en dehors de votre pays de
                r&eacute;sidence. Les lois sur la protection des donn&eacute;es varient en fonction
                des pays. Dans certains pays, les donn&eacute;es sont mieux prot&eacute;g&eacute;es
                que dans d&rsquo;autres. Quel que soit l&rsquo;endroit o&ugrave; vos informations
                sont trait&eacute;es, nous appliquons les m&ecirc;mes protections, qui sont
                d&eacute;crites dans les pr&eacute;sentes r&egrave;gles. Nous nous conformons
                &eacute;galement &agrave; certains cadres l&eacute;gaux en lien avec le transfert de
                donn&eacute;es, tels que les EU-US et Swiss-US Privacy Shield Frameworks.
            </p>
            <p className="p4">&nbsp;</p>
            <p className="p4">
                Lorsque nous recevons une r&eacute;clamation &eacute;crite officielle, nous y
                r&eacute;pondons en prenant contact avec l&rsquo;utilisateur qui l&rsquo;a
                formul&eacute;e. Nous coop&eacute;rons avec les autorit&eacute;s comp&eacute;tentes,
                y compris les autorit&eacute;s locales charg&eacute;es de la protection des
                donn&eacute;es, pour r&eacute;soudre tout litige concernant le transfert de vos
                donn&eacute;es que nous ne pouvons pas r&eacute;gler directement avec vous.
            </p>
            <p className="p4">&nbsp;</p>
            <p className="p4">&nbsp;</p>
            <p className="p4">Exigences europ&eacute;ennes</p>
            <p className="p4">&nbsp;</p>
            <p className="p4">
                Si la l&eacute;gislation de l&rsquo;Union europ&eacute;enne (UE) s&rsquo;applique au
                traitement de vos informations, nous mettons &agrave; votre disposition les outils
                d&eacute;crits dans les pr&eacute;sentes r&egrave;gles afin de vous permettre
                d&rsquo;exercer votre droit de demande d&rsquo;acc&egrave;s, de mise &agrave; jour,
                de suppression et de restriction du traitement de vos informations. Vous avez
                &eacute;galement le droit de vous opposer au traitement de vos informations ou
                d&rsquo;exporter ces derni&egrave;res vers un autre service.
            </p>
            <p className="p4">&nbsp;</p>
            <p className="p4">
                Nous traitons vos informations aux fins d&eacute;crites dans les pr&eacute;sentes
                r&egrave;gles et conform&eacute;ment aux fondements juridiques suivants :
            </p>
            <p className="p4">&nbsp;</p>
            <ul className="ul1">
                <li className="li4">Avec votre consentement.</li>
            </ul>
            <p className="p4">
                Nous vous demandons l&rsquo;autorisation de traiter vos informations &agrave; des
                fins sp&eacute;cifiques, et vous &ecirc;tes libre de revenir sur votre consentement
                &agrave; tout moment.
            </p>
            <p className="p4">&nbsp;</p>
            <ul className="ul1">
                <li className="li4">
                    Lorsque nous d&eacute;fendons des int&eacute;r&ecirc;ts l&eacute;gitimes.
                </li>
            </ul>
            <p className="p4">
                Nous traitons vos informations conform&eacute;ment &agrave; nos
                int&eacute;r&ecirc;ts l&eacute;gitimes, ainsi qu&rsquo;&agrave; ceux de tiers, tout
                en appliquant des mesures de protection appropri&eacute;es qui garantissent la
                confidentialit&eacute; de vos donn&eacute;es. Cela signifie que nous traitons vos
                informations notamment pour effectuer les op&eacute;rations suivantes :
            </p>
            <ul className="ul1">
                <ul className="ul1">
                    <li className="li4">
                        proposer nos services, en assurer le fonctionnement et les am&eacute;liorer
                        pour r&eacute;pondre aux besoins de nos utilisateurs&nbsp;;
                    </li>
                    <li className="li4">
                        d&eacute;velopper de nouveaux produits et de nouvelles
                        fonctionnalit&eacute;s utiles pour nos utilisateurs&nbsp;;
                    </li>
                    <li className="li4">
                        comprendre la fa&ccedil;on dont nos services sont utilis&eacute;s afin
                        d&rsquo;en assurer et d&rsquo;en am&eacute;liorer les performances&nbsp;;
                    </li>
                    <li className="li4">
                        personnaliser nos services afin d&rsquo;offrir une meilleure
                        exp&eacute;rience utilisateur&nbsp;;
                    </li>
                    <li className="li4">
                        d&eacute;celer, &eacute;viter ou traiter des activit&eacute;s frauduleuses,
                        des abus, des atteintes &agrave; la s&eacute;curit&eacute; ou tout
                        probl&egrave;me d&rsquo;ordre technique rencontr&eacute; par nos
                        services&nbsp;;
                    </li>
                    <li className="li4">
                        se pr&eacute;munir contre toute atteinte aux droits, aux biens ou &agrave;
                        la s&eacute;curit&eacute; de CHEVAL TV, de ses utilisateurs ou du public, en
                        application et dans le respect de la loi&nbsp;;
                    </li>
                    <li className="li4">
                        effectuer des recherches qui am&eacute;liorent nos services pour nos
                        utilisateurs et profitent au public&nbsp;;
                    </li>
                    <li className="li4">
                        remplir des obligations envers nos partenaires (d&eacute;veloppeurs et
                        d&eacute;tenteurs de droits, par exemple)&nbsp;;
                    </li>
                    <li className="li4">
                        faire respecter des d&eacute;cisions de justice, y compris pour constater
                        d&rsquo;&eacute;ventuels cas de non-respect des conditions
                        d&rsquo;utilisation applicables.
                    </li>
                </ul>
            </ul>
            <p className="p4">&nbsp;</p>
            <ul className="ul1">
                <li className="li4">Lorsque nous fournissons un service.</li>
            </ul>
            <p className="p4">
                Nous traitons vos donn&eacute;es afin de vous fournir un service que vous avez
                demand&eacute; et faisant l&rsquo;objet d&rsquo;un contrat.
            </p>
            <p className="p4">&nbsp;</p>
            <ul className="ul1">
                <li className="li4">
                    Lorsque nous nous conformons &agrave; des obligations l&eacute;gales.
                </li>
            </ul>
            <p className="p4">
                Nous traitons vos donn&eacute;es lorsque nous avons l&rsquo;obligation l&eacute;gale
                de le faire, par exemple pour nous conformer &agrave; des obligations judiciaires ou
                administratives.
            </p>
            <p className="p4">&nbsp;</p>
            <p className="p4">
                Pour toute question, vous pouvez contactez CHEVAL TV. Si vous avez des questions
                relatives aux droits qui vous sont octroy&eacute;s dans le cadre de votre
                l&eacute;gislation locale, vous pouvez contacter votre autorit&eacute; locale
                charg&eacute;e de la protection des donn&eacute;es.
            </p>
            <p className="p4">&nbsp;</p>
            <p className="p7">
                <br />
            </p>
        </>
    );
};

export default Privacy;
