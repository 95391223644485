import React, { useEffect, useState } from 'react';
import useAuth from 'hooks/useAuth';
import ApiService from 'services/ApiService';

const Success = ({ setTitle, setSubTitle }) => {
    const [processing, setProcessing] = useState(null);
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const { user } = useAuth();

    const onClickButtonHandler = e => {
        setProcessing(true);
        const data = new URLSearchParams();
        data.append('NoCli', user.NoCli);
        data.append('email', user.email);

        ApiService.post('user/createAuthentCode.php', data)
            .then(res => {
                if (!res.data.ret) throw new Error();
                setSuccess('Le code vous a été envoyé par mail');
            })
            .catch(err =>
                setError(
                    "Impossible d'envoyer un code pour le moment. Veuillez réessayer plus tard."
                )
            )
            .finally(() => setProcessing(false));
    };

    useEffect(() => {
        setTitle('Merci pour votre abonnement à Cheval TV');
        setSubTitle(
            "Cliquer sur le bouton ci-dessous afin de recevoir un code d'authentification par mail"
        );
    }, [setTitle, setSubTitle]);

    return (
        <>
            <p className="main__text"></p>
            {(!success || success.length <= 0) && (
                <button className="button" onClick={onClickButtonHandler} disabled={processing}>
                    M'envoyer un code
                </button>
            )}
            {error.length > 0 && (
                <p className="main__text main__text--center main__text--error">{error}</p>
            )}
            {success.length > 0 && (
                <p className="main__text main__text--center main__text--success">{success}</p>
            )}
        </>
    );
};

export default Success;
