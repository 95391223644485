import React, { createContext, useEffect, useReducer } from 'react';

import ApiService from 'services/ApiService';

const setSession = data => {
    localStorage.setItem('session', JSON.stringify(data));
};

const clearSession = () => {
    localStorage.removeItem('session');
};

const initialState = {
    isAuthenticated: false,
    isInitialized: false,
    userSubscribed: false,
    user: null,
    userValidated: false
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            const { isAuthenticated, user } = action.payload;
            const userValidated = user?.has_password || false;
            const userSubscribed =
                (user?.has_password &&
                    user?.TypeAbo &&
                    user?.TypeAbo != '0' &&
                    !user?.status?.discarded) ||
                false;

            return {
                ...state,
                isAuthenticated,
                isInitialized: true,
                userSubscribed,
                user,
                userValidated
            };
        }
        case 'LOGIN': {
            const { user } = action.payload;
            return {
                ...state,
                isAuthenticated: true,
                user
            };
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                userSubscribed: false,
                userValidated: false,
                user: null
            };
        }
        case 'REFRESH_USER': {
            const { user, isAuthenticated } = action.payload;
            const userValidated = user?.has_password || false;
            const userSubscribed =
                (user?.has_password &&
                    user?.TypeAbo &&
                    user?.TypeAbo != '0' &&
                    !user?.status?.discarded) ||
                false;

            return {
                ...state,
                isAuthenticated,
                user,
                userValidated,
                userSubscribed
            };
        }
        default: {
            return { ...state };
        }
    }
};

const AuthContext = createContext({
    ...initialState,
    login: () => Promise.resolve(),
    logout: () => {},
    createUser: Promise.resolve(),
    finalizeAccount: Promise.resolve(),
    getUser: Promise.resolve(),
    sendRegistrationEmail: Promise.resolve(),
    updateUser: Promise.resolve(),
    changeUserPassword: Promise.resolve(),
    unsubscribe: Promise.resolve(),
    deleteUser: Promise.resolve(),
    reactivateSubscription: Promise.resolve()
});

const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        (async () => {
            let token = new URLSearchParams(window.location.search).get('t');
            let NoCli;

            try {
                if (!token) {
                    const session = JSON.parse(localStorage.getItem('session'));
                    if (!session.NoCli) throw new Error();
                    NoCli = session.NoCli;
                } else {
                    const registrationToken = await checkRegistrationToken(token);

                    if (!registrationToken.validity) throw new Error();
                    NoCli = registrationToken.user;
                }
                const { status, user: newUserData } = await checkSubscription(NoCli);
                const user = { ...newUserData, status };
                const isAuthenticated = true;

                dispatch({
                    type: 'INIT',
                    payload: { isAuthenticated, user }
                });
            } catch (err) {
                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: false,
                        user: null
                    }
                });
            }
        })();
    }, []);

    const checkRegistrationToken = token => {
        const data = new URLSearchParams();
        data.append('code', token);
        return ApiService.post('user/checkRegistrationCode.php', data)
            .then(res => {
                if (!res.data.ret) throw new Error();
                return res.data.data;
            })
            .catch(err => {
                return false;
            });
    };

    const checkSubscription = noCli => {
        return ApiService.get(`user/getUserInfo.php?NoCli=${noCli}`)
            .then(({ data }) => {
                // TODO change condition
                if ((!data.ret && data.data === 'authentication') || data.data.status.discarded) {
                    throw new Error();
                }
                return data.data;
            })
            .catch(() => {
                return false;
            });
    };

    const createUser = async email => {
        const data = new URLSearchParams();
        data.append('email', email);

        const res = await ApiService.post('user/createAccount.php', data);

        return res.data;
    };

    const finalizeAccount = async user => {
        const data = new URLSearchParams();
        for (const [key, value] of Object.entries(user)) {
            data.append(key, value);
        }

        const res = await ApiService.post('user/finalizeAccount.php', data);
        console.log('finalize res', res);

        if (!res.data.ret) return false;

        getUser(user.NoCli);
    };

    const getUser = async noCli => {
        const { user, status } = await ApiService.get(`user/getUserInfo.php?NoCli=${noCli}`).then(
            res => res.data.data
        );

        const newUser = {
            ...user,
            status
        };

        setSession(newUser);
        dispatch({
            type: 'REFRESH_USER',
            payload: {
                isAuthenticated: true,
                user: newUser
            }
        });
    };

    const updateUser = async user => {
        const data = new URLSearchParams();
        for (const [key, value] of Object.entries(user)) {
            data.append(key, value);
        }

        const res = await ApiService.post('user/updateUser.php', data);

        if (!res.data.ret) return false;

        getUser(user.NoCli);
        return true;
    };

    const deleteUser = async user => {
        const data = new URLSearchParams();
        data.append('NoCli', user.NoCli);
        data.append('passwd', user.passwd);

        const res = await ApiService.post('user/deleteUser.php', data);

        if (!res.data.ret) return false;

        dispatch({
            type: 'LOGOUT'
        });

        return true;
    };

    const login = async (email, password) => {
        const params = new URLSearchParams();
        params.append('email', email);
        params.append('password', password);

        const res = await ApiService.post('user/login.php', params);
        if (res.data.ret) {
            getUser(res.data.data.user.NoCli);
            return true;
        }
        return false;
    };

    const logout = () => {
        clearSession();
        dispatch({ type: 'LOGOUT' });
    };

    const sendRegistrationEmail = (email, noCli) => {
        const data = new URLSearchParams();
        data.append('email', email);

        return ApiService.post('user/sendRegistrationEmail.php', data).then(res => res.data);
    };

    const changeUserPassword = async user => {
        const data = new URLSearchParams();
        data.append('NoCli', user.NoCli);
        data.append('passwd', user.passwd);
        data.append('newPasswd', user.newPasswd);

        const res = await ApiService.post('user/changeUserPassword.php', data);

        return res.data;
    };

    const unsubscribe = async () => {
        const data = new URLSearchParams();
        data.append('NoCli', state.user.NoCli);

        const res = await ApiService.post('user/suspendUser.php', data);
        console.log(res);

        if (!res.data.ret) return false;

        getUser(state.user.NoCli);
        return res.data.ret;
    };

    const reactivateSubscription = async () => {
        const data = new URLSearchParams();
        data.append('NoCli', state.user.NoCli);

        const res = await ApiService.post('user/reactivateSubscription.php', data);
        if (!res.data.ret) return false;

        getUser(state.user.NoCli);
        return res.data.ret;
    };

    return (
        <AuthContext.Provider
            value={{
                ...state,
                changeUserPassword,
                createUser,
                finalizeAccount,
                getUser,
                login,
                logout,
                sendRegistrationEmail,
                updateUser,
                unsubscribe,
                deleteUser,
                reactivateSubscription
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export { AuthContext as default, AuthProvider };
