import React, { useEffect, useMemo, useState } from 'react';

import useAuth from 'hooks/useAuth';
import Input from 'components/Input';

const Home = ({ setTitle, setSubTitle, emailValue, emailError }) => {
    const { user, login, sendRegistrationEmail } = useAuth();
    const [passwordValue, setPasswordValue] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [notActiveMode, setNotActiveMode] = useState(false);

    const onClickButtonHandler = e => {
        login(emailValue, passwordValue).then(res => {
            if (!res) setErrorMessage('Mot de passe incorrect');
        });
    };

    const onClickLinkHandler = e => setNotActiveMode(true);

    const onClickLoginHandler = e => setNotActiveMode(false);

    const onChangeHandler = e => {
        const { value } = e.target;
        setPasswordValue(value);
    };

    const onClickActivateHandler = e => {
        sendRegistrationEmail(emailValue).then(res => {
            if (res.ret) {
                setErrorMessage('');
                setSuccessMessage('Un e-mail contenant le lien de vérification vous a été envoyé');
            } else if (res.data === 'User already activated') {
                setSuccessMessage('');
                setErrorMessage('Le compte est déja vérifié, veuillez vous connecter');
            } else if (res.data === 'User not found') {
                setSuccessMessage('');
                setErrorMessage("Ce compte n'existe pas");
            } else {
                setSuccessMessage('');
                setErrorMessage('Une erreur est survenue, veuillez réessayer plus tard');
            }
        });
    };

    useEffect(() => {
        setTitle('Entrez vos identifiants pour vous connecter');
        setSubTitle(
            "Si votre compte n'est pas encore vérifié vous pouvez le vérifier via le lien qui vous a été envoyé par mail"
        );
    }, [setTitle, setSubTitle]);

    return (
        <>
            {!notActiveMode ? (
                <>
                    <Input
                        label="Mot de passe"
                        name="password"
                        onChange={onChangeHandler}
                        placeholder="********"
                        type="password"
                        value={passwordValue}
                    />
                    <button className="button" onClick={onClickButtonHandler}>
                        Me connecter
                    </button>
                    <div
                        className="main__text main__text--center main__link"
                        onClick={onClickLinkHandler}
                    >
                        Mon compte n'est pas vérifié
                    </div>
                </>
            ) : (
                <>
                    <button className="button" onClick={onClickActivateHandler}>
                        Activer mon compte
                    </button>
                    <div
                        className="main__text main__text--center main__link"
                        onClick={onClickLoginHandler}
                    >
                        Me connecter
                    </div>
                </>
            )}
            {successMessage.length > 0 && (
                <div
                    className="main__text main__text--center main__text--success"
                    onClick={onClickLinkHandler}
                >
                    {successMessage}
                </div>
            )}
            {errorMessage.length > 0 && (
                <div
                    className="main__text main__text--center main__text--error"
                    onClick={onClickLinkHandler}
                >
                    {errorMessage}
                </div>
            )}
        </>
    );
};

export default Home;
