import React, { useEffect } from 'react';

const LegalStyle = () => (
    <style type="text/css">{`
        p.p1 {
            margin: 0.0px 0.0px 0.0px 0.0px;
            font: 12.0px
        }

        p.p2 {
            margin: 0.0px 0.0px 0.0px 0.0px;
            font: 12.0px;
            min-height: 14.0px
        }

        p.p3 {
            margin: 0.0px 0.0px 30.0px 0.0px;
            font: 24.0px;
            color: #1d1d1b
        }

        p.p4 {
            margin: 0.0px 0.0px 0.0px 0.0px;
            font: 13.0px;
            color: #1d1d1b
        }

        p.p5 {
            margin: 0.0px 0.0px 0.0px 0.0px;
            font: 24.0px;
            color: #1d1d1b
        }

        p.p6 {
            margin: 5.0px 0.0px 5.0px 0.0px;
            font: 13.0px;
            color: #1d1d1b
        }

        p.p7 {
            margin: 0.0px 0.0px 0.0px 0.0px;
            font: 13.0px;
            color: #1d1d1b;
            min-height: 15.0px
        }

        li.li4 {
            margin: 0.0px 0.0px 0.0px 0.0px;
            font: 13.0px;
            color: #1d1d1b
        }

        span.s1 {
            color: #000000
        }

        ul.ul1 {
            list-style-type: disc
        }
        </style>
        <style type="text/css">
        p.p1 {
            margin: 0.0px 0.0px 0.0px 0.0px;
            font: 12.0px;
            min-height: 14.0px
        }

        p.p2 {
            margin: 0.0px 0.0px 30.0px 0.0px;
            font: 24.0px;
            color: #1d1d1b
        }

        p.p3 {
            margin: 0.0px 0.0px 0.0px 0.0px;
            font: 13.0px;
            color: #1d1d1b
        }

        p.p4 {
            margin: 0.0px 0.0px 0.0px 0.0px;
            font: 24.0px;
            color: #1d1d1b
        }

        p.p5 {
            margin: 0.0px 0.0px 0.0px 0.0px;
            font: 13.5px;
            color: #1d1d1b
        }

        p.p6 {
            margin: 0.0px 0.0px 0.0px 0.0px;
            font: 13.0px;
            color: #1d1d1b;
            min-height: 15.0px
        }
    `}</style>
);

const Legal = ({ setTitle, setSubTitle }) => {
    useEffect(() => {
        setTitle('');
        setSubTitle('');
    }, [setTitle, setSubTitle]);

    return (
        <>
            <LegalStyle />
            <p class="p1">
                <br />
            </p>
            <p class="p1">
                <br />
            </p>
            <p class="p2">
                <strong>Mentions L&eacute;gales</strong>
            </p>
            <p class="p3">&nbsp;</p>
            <p class="p4">Cookies et publicit&eacute; en ligne</p>
            <p class="p3">
                <strong>&nbsp;</strong>
            </p>
            <p class="p3">
                CHEVAL TV et ses Fournisseurs de services utilisent des cookies et d&apos;autres
                technologies (comme des balises web), ainsi que des identifiants
                r&eacute;initialisables, pour diff&eacute;rentes raisons. Ces technologies nous
                permettent notamment de faciliter votre acc&egrave;s &agrave; nos services parce que
                nous nous souvenons de vous chaque fois que vous vous connectez, ainsi que
                d&apos;assurer et d&apos;analyser nos prestations. Nous utilisons &eacute;galement
                des cookies et des identifiants r&eacute;initialisables pour conna&icirc;tre plus
                pr&eacute;cis&eacute;ment nos utilisateurs, mieux comprendre leurs
                int&eacute;r&ecirc;ts et cibler nos publicit&eacute;s et nos campagnes marketing.
                Cette section vise &agrave; vous informer de l&apos;utilisation que nous faisons de
                telles technologies. Elle explique les types de technologies dont nous nous servons,
                leur utilit&eacute; et les choix dont vous disposez concernant leur utilisation.
            </p>
            <p class="p3">&nbsp;</p>
            <p class="p5">
                <strong>Que sont les cookies ?</strong>
            </p>
            <p class="p3">
                <strong>&nbsp;</strong>
            </p>
            <p class="p3">
                Les cookies sont de petits fichiers de donn&eacute;es qui sont stock&eacute;s sur
                votre appareil lorsque vous visitez et utilisez des sites web et des services en
                ligne. Ils sont utilis&eacute;s couramment pour faire fonctionner les sites web ou
                pour en optimiser le fonctionnement, pour fournir des renseignements et pour
                personnaliser les services et la publicit&eacute;. Les cookies ne sont pas les
                seules technologies exploitables &agrave; ces fins. Nous utilisons &eacute;galement
                d&apos;autres types de technologies similaires. Consultez les d&eacute;tails
                ci-dessous pour en savoir plus et obtenir des exemples.
            </p>
            <p class="p3">&nbsp;</p>
            <p class="p3">
                <strong>
                    Que sont les identifiants d&apos;appareils r&eacute;initialisables ?
                </strong>
            </p>
            <p class="p3">
                <strong>&nbsp;</strong>
            </p>
            <p class="p3">
                Les identifiants r&eacute;initialisables (&eacute;galement appel&eacute;s
                &quot;identifiants publicitaires&quot;), semblables aux cookies, se trouvent sur de
                nombreux appareils mobiles et tablettes (il s&apos;agit par exemple des identifiants
                pour annonceurs ou IDFA sur les appareils iOS et des identifiants publicitaires
                Google sur les appareils Android), ainsi que sur certains appareils de streaming.
                &Agrave; l&apos;instar des cookies, les identifiants r&eacute;initialisables
                permettent de rendre le contenu publicitaire en ligne plus pertinent.
            </p>
            <p class="p3">&nbsp;</p>
            <p class="p3">&nbsp;</p>
            <p class="p5">
                <strong>
                    Pourquoi CHEVAL TV utilise-t-il des cookies et des identifiants
                    r&eacute;initialisables ?
                </strong>
            </p>
            <p class="p3">
                <strong>&nbsp;</strong>
            </p>
            <p class="p3">
                Cookies n&eacute;cessaires : ces cookies sont essentiels au fonctionnement de notre
                site web et de notre service en ligne. Par exemple, CHEVAL TV
                <span class="Apple-converted-space">&nbsp;&nbsp;</span>et ses Fournisseurs de
                services peuvent utiliser ce type de cookie pour authentifier nos utilisateurs et
                les identifier lorsqu&apos;ils sont connect&eacute;s &agrave; nos sites Web et
                &agrave; nos applications, afin de leur fournir notre service. Ces cookies nous
                permettent &eacute;galement de faire respecter nos Conditions d&apos;utilisation,
                d&apos;&eacute;viter les fraudes et d&apos;assurer la s&eacute;curit&eacute; de
                notre service.
            </p>
            <p class="p3">
                Cookies de performance et de fonctionnalit&eacute; : ces cookies ne sont pas
                essentiels, mais ils nous aident &agrave; personnaliser votre exp&eacute;rience en
                ligne sur CHEVAL TV et &agrave; l&apos;am&eacute;liorer. Ils nous permettent, par
                exemple, de m&eacute;moriser vos pr&eacute;f&eacute;rences et ils vous
                &eacute;vitent de devoir ressaisir des donn&eacute;es que vous nous avez
                d&eacute;j&agrave; communiqu&eacute;es (notamment lors de votre inscription). Nous
                utilisons &eacute;galement ce type de cookie pour collecter des donn&eacute;es
                (comme les pages les plus populaires, les taux de conversion, les habitudes de
                lecture ou le nombre de clics) sur la mani&egrave;re dont les internautes utilisent
                le service CHEVAL TV, afin d&apos;am&eacute;liorer et de personnaliser notre site
                web et notre service, et pour r&eacute;aliser des &eacute;tudes de march&eacute;. La
                suppression de ces types de cookies peut limiter les fonctionnalit&eacute;s de notre
                service.
            </p>
            <p class="p3">
                Cookies publicitaires et identifiants r&eacute;initialisables : les cookies et
                identifiants r&eacute;initialisables exploitent des informations sur votre
                utilisation de ce site web, d&apos;applications et d&apos;autres sites Web, ainsi
                que sur votre r&eacute;action aux publicit&eacute;s et aux e-mails, dans le but de
                vous proposer des publicit&eacute;s susceptibles de vous int&eacute;resser
                davantage. Ces publicit&eacute;s sont qualifi&eacute;es de &quot;publicit&eacute;s
                cibl&eacute;es par centres d&apos;int&eacute;r&ecirc;t&quot;. De nombreux cookies
                publicitaires associ&eacute;s &agrave; notre service appartiennent &agrave; nos
                Fournisseurs de services.
            </p>
            <p class="p3">
                Comment puis-je exercer mon choix concernant l&apos;utilisation des cookies et des
                identifiants r&eacute;initialisables ?
            </p>
            <p class="p3">
                Pour en savoir plus sur les cookies cr&eacute;&eacute;s par notre site web et sur
                les autres m&eacute;thodes de suivi en ligne (notamment la collecte de
                donn&eacute;es par des tiers sur vos activit&eacute;s en ligne au fil du temps et
                via des services en ligne ou des sites tiers afin d&apos;optimiser les
                publicit&eacute;s cibl&eacute;es par centres d&apos;int&eacute;r&ecirc;t) et pour
                exercer votre choix les concernant contactez-nous &agrave; abonnement@chevaltv.fr.
            </p>
            <p class="p3">&nbsp;</p>
            <p class="p5">
                <strong>
                    Comment CHEVAL<span class="Apple-converted-space">&nbsp;&nbsp;</span>TV
                    utilise-t-il les balises Web et les autres technologies ?
                </strong>
            </p>
            <p class="p3">
                <strong>&nbsp;</strong>
            </p>
            <p class="p3">
                Les cookies sont souvent associ&eacute;s &agrave; des balises web (&eacute;galement
                appel&eacute;es &quot;GIF invisibles&quot; ou &quot;pixels-espions&quot;). CHEVAL
                <span class="Apple-converted-space">&nbsp;&nbsp;</span>TV et ses Fournisseurs de
                services sont susceptibles d&apos;utiliser des balises Web aux m&ecirc;mes fins que
                les cookies, par exemple pour comprendre et am&eacute;liorer l&apos;utilisation de
                notre service, optimiser les performances du site, analyser le trafic des
                internautes et les actions effectu&eacute;es sur notre site, ou encore comprendre
                les interactions avec nos campagnes commerciales (notamment les e-mails et les
                publicit&eacute;s en ligne sur des sites tiers). Comme les balises Web sont
                g&eacute;n&eacute;ralement associ&eacute;es &agrave; des cookies, dans la plupart
                des cas, le fait de refuser les cookies amoindrit l&apos;efficacit&eacute; des
                balises web qui leur sont associ&eacute;es.
            </p>
            <p class="p3">&nbsp;</p>
            <p class="p3">&nbsp;</p>
            <p class="p3">
                Nous utilisons d&apos;autres technologies semblables aux cookies, comme le stockage
                et les plug-ins pour navigateurs web (par exemple, HTML5, IndexedDB et WebSQL).
                &Agrave; l&apos;instar des cookies, certaines de ces technologies peuvent stocker de
                petites quantit&eacute;s de donn&eacute;es sur votre appareil. Nous sommes
                susceptibles d&apos;utiliser ces technologies, entre autres, pour les m&ecirc;mes
                finalit&eacute;s que les cookies : notamment pour faire respecter nos conditions,
                pour &eacute;viter les fraudes et pour analyser l&apos;utilisation de notre service.
                Vous pouvez exercer votre choix concernant l&apos;utilisation de ces technologies de
                diff&eacute;rentes mani&egrave;res. Par exemple, les navigateurs les plus connus
                permettent de purger leur stockage, g&eacute;n&eacute;ralement via les
                param&egrave;tres ou les pr&eacute;f&eacute;rences. Pour en savoir plus, veuillez
                consulter l&apos;aide en ligne ou la section de support de votre navigateur. Vous
                pouvez &eacute;galement d&eacute;sactiver d&apos;autres technologies comme le
                stockage Silverlight directement dans l&apos;application.
            </p>
            <p class="p6">
                <br />
            </p>
            <p class="p7">
                <br />
            </p>
        </>
    );
};

export default Legal;
