import Router from 'components/Router';
import { AuthProvider } from 'contexts/AuthContext';
import 'style/main.scss';

const App = () => {
    return (
        <AuthProvider>
            <Router />
        </AuthProvider>
    );
};

export default App;
